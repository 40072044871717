import { CREATE_LICENSE_RED, DELETE_LICENSE_RED, GET_LICENSE_RED, UPDATE_LICENSE_RED } from "../Constant";

export default function LicenseReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_LICENSE_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_LICENSE_RED:
            return action.payload

        case UPDATE_LICENSE_RED:
            // console.log(action.payload)
            index=state.findIndex((x)=>x._id === action.payload._id)
            state[index].name=action.payload.name
            state[index].pic=action.payload.pic
            state[index].aboutLicense=action.payload.aboutLicense
            state[index].applyLicenseName=action.payload.applyLicenseName
            state[index].applyLicenseDetails=action.payload.applyLicenseDetails
            state[index].getLicenseName=action.payload.getLicenseName
            state[index].getLicenseDetails=action.payload.getLicenseDetails
            return state
        
        case DELETE_LICENSE_RED:
            return state.filter((x)=> x._id !== action.payload._id)

        
        default :
            return state
    }
}