import { CREATE_QUERY_RED, DELETE_QUERY_RED, GET_QUERY_RED,  } from "../Constant";

export default function QueryReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_QUERY_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_QUERY_RED:
            return action.payload

        // case UPDATE_QUERY_RED:
            // index=state.findIndex((x)=>x.id === action.payload._id)
            // state[index].name=action.payload.name
            // state[index].pic=action.payload.pic
            // state[index].address=action.payload.address
            // return state
        
        case DELETE_QUERY_RED:
            return state.filter((x)=> x.id !== action.payload._id)

        
        default :
            return state
    }
}