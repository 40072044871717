import React, { useState } from 'react'
import {  Link, useNavigate } from 'react-router-dom'
import FormValidation from '../Validation/FormValidation'



export default function AdminCreateProfile() {
  let [pstatus, setPstatus]=useState(false)
let [show,setShow]=useState(false)
  let navigate = useNavigate()
  let [data, setData]= useState({
    username:"",
    name:"",
    email:"",
    phone:"",
    password:"",
  
  })
  let [errorMessage,setErrorMessage]=useState({
    name: "Full  Name Field is Mandatory",
    username: "User Name Field is Mandatory",
    email: "Email Field is Mandatory",
    phone: "Phone Field is Mandatory",
    password: "Password Field is Mandatory",
  })


  // getInputData

  function getInputData(e) {
    var { name, value } = e.target
   if(name === "name"  || name==="email"  ||  name==="username"  ||  name==="phone"  ||  name==="password") {
    setErrorMessage((old) => {
      return {
        ...old,
        [name]: FormValidation(e),
      };
    });

   }
 
    setData((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
  }


  async function postData(e) {
    e.preventDefault();
    if (data.password === data.password) {
      let error = Object.values(errorMessage).find((x) => x !== "");
      if (error) 
        setShow(true)
         else {
        let  item = {
            name: data.name,
            username: data.username,
            email: data.email,
            phone: data.phone,
            password: data.password,
            role: "Buyer",
          };
    let  resposne = await fetch("https://pgc.onrender.com/api/admin", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "authorization": localStorage.getItem("token")
            },
            body: JSON.stringify(item), 
          })
          resposne = await resposne.json();
          
          if (resposne.result === "Done"){
            localStorage.setItem("login" , true)
            localStorage.setItem("name" , resposne.data.name)
            localStorage.setItem("userid" ,resposne.data._id)
            localStorage.setItem("role" , resposne.data.role)
            localStorage.setItem("token" , resposne.token)
            if(resposne.data.role === "Admin")
            navigate("/admin")
             else
             navigate("/")
          }
          else{
            setShow(true)
              setErrorMessage((old)=>{
                
                return{
                  ...old,
                  
                  "username" : resposne.reason.username ?? "",
                  "email" : resposne.reason.email ?? "",
                  "password" : resposne.reason.password ?? "",
                }
              })
            
          }
        
      } 
    }
     else{
      setShow(true)
      setErrorMessage((old) => {
        return {
          ...old,
        };
      });
      setShow(true);
    }
  }


  return (
  <>
  <div className="container-fluid">
    <div className="row">
      <h4 className='text-center bg-success text-light py-2'>User Create Profile</h4>

      <div className="col-md-9 m-auto license-create-form">
      <h4 className='text-center text-light bg-primary p-2'>Create Profile<Link to="/admin-license"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      <form onSubmit={postData}>
    <div className="row">
 
  <div className="  mb-3">
    <label for="exampleInputName" className="form-label">Name*</label>
    <input type="name" name='name' onChange={getInputData} className="form-control" id="exampleInputName"/>
    {show && errorMessage.name? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
    </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label for="exampleInputUsername" className="form-label">UserName*</label>
    <input type="text" name='username' onChange={getInputData} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
  {show && errorMessage.username ? <p className='text-danger text-capitalize'>{errorMessage.username}</p>:""}
  </div>
  <div className=" col-md-6 mb-3">
    <label for="exampleInputEmail" className="form-label">Email Address* </label>
    <input type="email" name='email' onChange={getInputData}  className="form-control" id="exampleInputEmail"/>
    {show && errorMessage.email ? <p className='text-danger text-capitalize'>{errorMessage.email}</p>:""}
    </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label for="exampleInputPhone" className="form-label">Phone* </label>
    <input type="number" name='phone' onChange={getInputData} className="form-control" id="exampleInputPhone" aria-describedby="emailHelp"/>
    {show && errorMessage.phone ? <p className='text-danger text-capitalize'>{errorMessage.phone}</p>:""}
    </div>
  <div className=" col-md-6 mb-3">
    <label for="exampleInputPassword" className="form-label">Password* </label>
    <input type={`${pstatus ? "text" :"password"}`} name='password' onChange={getInputData} className="form-control" id="exampleInputPasswod" aria-describedby="emailHelp"/>
    <span className='password-icon'  onClick={()=>setPstatus(!pstatus)}>{pstatus?<i className="bi bi-eye-fill float-end password-icon "></i>:<i class="bi bi-eye-slash-fill  float-end password-icon"></i>}
    {show && errorMessage.password ? <p className='text-danger text-capitalize'>{errorMessage.password}</p>:""}
     </span>
    </div>

  </div>
  <button type="submit" className="btn btn-primary w-100">Create</button>
</form> 
    </div>
      </div>
    </div>
    
  
  </>
  )
}
