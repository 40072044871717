import React, { useEffect, useState } from 'react'
import AdminHome from '../AdminHome'
import { DataGrid} from "@mui/x-data-grid";
// import { Link } from 'react-router-dom';
 import { getQuery,deleteQuery } from '../../../Store/ActionCreaters/QueryActionCreaters';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function AdminAllQuery() {
    let[data,setData]=useState([])
    const columns = [
        { field: "_id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Name", width: 200 },
        { field: "phone", headerName: "Phone", width:200 },
        { field: "email", headerName: "Email", width: 250 },
        { field: "message", headerName: "Message", width: 200 },
        { field: "select", headerName: "Type of Query", width: 300 },

        { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
      ];

      let dispatch = useDispatch()
      let QueryStateData = useSelector((state)=>state.QueryStateData)
    //   var {_id} =useParams()

  function deleteData(_id) {
           if (window.confirm("You Are Sure to Delete This Item : ")) {
            // let response =await fetch("http://localhost:8000/query/"+id,{
            //     method:"DELETE",
            //     headers:{
            //         "content-type":"application/json"
            //     }
            // })
            // response = await response.json()
            dispatch(deleteQuery({_id:_id}))
            getAPIData()

           }
 }

// get api data 
 function getAPIData(){
    // let response = await fetch ("http://localhost:8000/query",{
    //     method:"GET",
    //     headers:{
    //         "content-type":"application/json"
    //     }
    // })
    // response =await response.json()
    dispatch(getQuery())
    if(QueryStateData.length){
        setData(QueryStateData)
    }
    else
    setData([])
}

useEffect(()=>{
    getAPIData()
},[QueryStateData?.length])
  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'>Admin All Query Page</h2>
    <AdminHome/>
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>All Query Table</h2>
        {/* <table className="table table-hover table-bordered">
                <thead>
                    <tr>
                       <th>Id</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Type of License</th>
                        <th>Query</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item,index)=>{
                        return(
                            <tr key={index}>
                                <td >{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td>{item.licenseType}</td>
                                <td>{item.message}</td>
                                <td><i className="bi bi-trash-fill text-danger fs-5" onClick={()=>deleteData(item.id)}></i></td>
                            </tr>
                        )
                    })}
                </tbody>

            </table> */}
     {  QueryStateData?.length?
       <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  getRowId={(row) => row._id} 
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10,20,50,100]}
                  checkboxSelection={false}
                />
       </div>
       : <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> Query Table Record Not Found</h4>
    }
        </div>
        </div>
</div>
        </>
  )
}
