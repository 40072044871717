import React from 'react'
import Slider from "./partials/Slider"
import Service from './partials/Service'

import About from './partials/About'
import Client from './partials/Client'
import Testimonials from './partials/Testimonials'
import QueryForm from './partials/QueryForm'
import OurServices from './partials/OurServices'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
   <>
   <header>
    <Slider/>
    <About/>
    <div className="whatsapp">
    <Link to="https://wa.me/+919971522879" target='_blank' >  <img src="./img/whatapp.svg" alt='whatsapp'  /> </Link>
    </div>
    <Service/>
    <Client/>
    <Testimonials/>
    <QueryForm/>
 
    <OurServices/>
    </header>

   </>
  )
}
