import React, { useEffect, useState } from 'react'
import AdminHome from '../AdminHome'
import { DataGrid} from "@mui/x-data-grid";

 

export default function AdminAllUsers() {
    let[data,setData]=useState([])
    const columns = [
        { field: "_id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Name", width: 200 },
        { field: "username", headerName: "User Name", width: 200 },
        { field: "phone", headerName: "Phone", width:200 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "role", headerName: "Role", width: 150 },
        { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
      ];

 async function deleteData(_id) {
           if (window.confirm("You Are Sure to Delete This Item : ")) {
            let response =await fetch("https://pgc.onrender.com/api/admin/"+_id,{
                method:"DELETE",
                headers:{
                    "content-type":"application/json",
                    "authorization":localStorage.getItem("token")
                }
            })
            response = await response.json()
            getAPIData()

           }
 }

// get api data 
 async function getAPIData(){
    var response = await fetch ("https://pgc.onrender.com/api/admin",{
        method:"GET",
        headers:{
            "content-type":"application/json",
            "authorization":localStorage.getItem("token")

        }
    })
   response = await response.json()
    if(response.result === "Done"){
     setData( response.data.filter((x)=>x.role === "Buyer"))
        
    }
    else
    setData([])
}

useEffect(()=>{
    getAPIData()
},[data.length])
  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'> All Users Page</h2>
    <AdminHome/>
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>All Users Table</h2>
        {data.length === 0? 
        
        <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> Users Table Record Not Found</h4>:
        <div style={{ height: 400, width: "100%" }}>
                 <DataGrid
                   rows={data}
                   columns={columns}
                   getRowId={(row) => row._id} 
                   initialState={{
                     pagination: {
                       paginationModel: { page: 0, pageSize: 5 },
                     },
                   }}
                    checkboxSelection={false}
                 />
             
         </div>
     }
        </div>
        </div>
</div>
        </>
  )
}
