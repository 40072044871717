import React, { useEffect, useState } from 'react'
import BannerWebSite from './partials/BannerWebSite'
import { Link, useNavigate, useParams } from 'react-router-dom';
import {getWebQuery, createWebQuery} from "../Store/ActionCreaters/WebQueryActionCreaters"
import { useDispatch, useSelector } from 'react-redux';
import FormValidation from './Validation/FormValidation';
import toast from 'react-hot-toast';
import webdata from '../Data/webdata';
import { Helmet } from 'react-helmet';

export default function WebDevelopment() {

let [input,setInput]=useState({
  name:"",
  phone:"",
  email:""
})
 let [errorMessage,setErrorMessage]= useState({
  name:"Name Field Is Required",
  phone:"Phone Field Is Required",
  email:"Email Field Is Required",
 })
 let[show, setShow]=useState(false)
 let dispatch = useDispatch()
 let WebQueryStateData =useSelector((x)=>x.WebQueryStateData)
 let navigate = useNavigate()
 var {_id} = useParams()

 function getInputData(e){
  let {name,value}= e.target;
  if(name==="name" || name ==="phone" || name==="email"){
    setErrorMessage((old)=>{
      return{
        ...old,
        [name]:FormValidation(e)
      }
    })
  }
  setInput((old)=>{
    return{
      ...old,
      [name]:value
    }
  })
 }

 function postData(e){
  e.preventDefault()
  let error = Object.values(errorMessage).find((x)=> x.length>0)
  if(error){
    setShow(true)
  }
  else{
    let item= WebQueryStateData.find((x)=>x.phone === input.phone && x._id === _id)    
    if(item){
      setShow(true)
      toast.error("Phone Number Is Already Exist.")
      setErrorMessage((x)=>{
        return{
          ...x,
          "phone":"Phone Number Is Already Exist"
        }
      })
      
    }
    else{
      dispatch(createWebQuery({...input}))
      navigate("/")
    }
  }
 }

 useEffect(()=>{
  (()=>{
    dispatch(getWebQuery())
  })()
 },[WebQueryStateData.length])
  return (
<>
<Helmet>
      <title>PGC | Web Development </title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>
<div className="container-fluid">
    <BannerWebSite/>
    <div className="row">
      <div className="col-12 lic-card mx-1 my-1  ">
        <h1 className='text-center'> Website Design and Development</h1>
        <p  className='text-center'>At <span style={{fontWeight:"600" , alignItems:"center", textAlign:"center"}}>Perfect Global Consultancy</span>, we are passionate about building innovative, functional, and visually stunning websites that help businesses grow. With years of experience in the web development industry, our team of skilled developers, designers, and strategists are dedicated to creating high-quality,
             custom solutions tailored to meet your unique needs.</p>
      </div>
      <div className="row m-auto py-2 ">
  <div className="col-md-6">
    <div className=" card col mx-2 lic-card ">
      <h3 className='mx-2 my-2'>Get your business Visible Online ? </h3>
      <p className='mx-2' style={{textAlign:" justify"}}>Ensure your website is user-friendly, mobile-optimized, and visually appealing
      Optimize your website with relevant keywords, good page load speeds, proper metadata, and high-quality content.</p>
    </div>
    <div className="card col mx-2 py-2  lic-card ">
    <h3 className='mx-2'>Collaboration and Customization </h3>
      <p className='mx-2' style={{textAlign:" justify"}}>
      At Perfect Global Consultancy, we view every project as a collaboration between our team and yours. We value your 
          insights and ideas and use them to create a website that truly resonates with your business goals. Whether you have a 
        specific vision in mind or need guidance in shaping your digital strategy, we're here to work hand in hand with you..</p>
        </div>
    </div>

<div className="col-md-5 m-auto my-5 lic-card1" style={{height:"400px"}} >
    <h3 className='text-center' style={{marginBottom:"40px"}}> <i>Enquire Now </i></h3>
  <form onSubmit={postData} >
     <div className="row g-3">
        <div className="row">
        <div className="col my-2">
             <div className="form-floating">
                 <input type="text" className={`form-control  ${show && errorMessage.name ? "border-danger": "border-primary"}`}  onChange={getInputData}  name="name"  id="subject" placeholder="Name" />
                 <label htmlFor="subject">Name*</label>
             </div>
         </div>
        </div>
        <div className="row py-2 ">
        <div className="col">
             <div className="form-floating"> 
                 <input type="email" className={`form-control  ${show && errorMessage.email ? "border-danger": "border-primary"}`}  name="email"  onChange={getInputData} id="subject" placeholder="Email" />
                 <label htmlFor="subject">Email*</label>
             </div>
         </div>
        </div>
        <div className="row py-2" >
        <div className="col">
             <div className="form-floating">
                 <input type="number" className={`form-control  ${show && errorMessage.phone ? "border-danger": "border-primary"}`}  name="phone" onChange={getInputData} id="subject" placeholder="Phone" />
                 <label htmlFor="subject">Phone*</label>
             </div>
         </div>
        </div>
        
         <div className="col-12">
             <button className="btn btn-warning w-100 py-2  m-auto"  type="submit">Send Message</button>
         </div>
    </div>
   </form>
</div>
    </div>
    </div>

<div className="row">
    <div className="col">
        <h3 className='text-center'> OUR <u className='text-warning' style={{fontFamily:"Roboto , sans-serif"}}>WORKS</u> </h3>
        <p className='text-center' style={{fontFamily:"Roboto , sans-serif", fontWeight:"300",textAlign:" justify"}} >We have design & developed for all type websites almost all Type industry .<br/> Developed Like: e-commerce, 
         professional, portfolio Website  ERP,& CMS and many more</p>
    </div>
</div>
</div>

<div className="container-fluid my-2">
<div className="row">
          {webdata?.map((item, index) => {
            return (
              <div className=" col-lg-3 col-md-4 col-sm-6  web-link wow fadeInUp col-12 " key={index}>
                <Link to={item.link} target='_blank' >
                <div
                  className="card my-2 my-4 mx-2 mx-auto licenseCard1"
                  style={{ width: "17rem" }}
                >
                  <img
                    src={item.img}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body ">
                    <h5 className="card-text text-center text-dark">
                     {item.name}
                    </h5>
                  </div>
                </div>
                </Link>
              </div>
            );
          })}

        </div>
</div>
{/* FAQ */}
<div className="accordion accordion-flush my-5" id="accordionFlushExample">

    <h1 className='text-center ' style={{fontWeight:"700", marginBottom:"30px"}}>FREQUENTLY <span className='text-warning'>ASKED</span> QUESTIONS</h1>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
     1. What technologies do you use for web development?
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">We use a variety of modern web technologies to create websites that are fast, secure, and scalable. Our team is experienced in HTML5, CSS3, JavaScript, React, Angular, PHP, WordPress, WooCommerce, Laravel, and other frameworks depending on the project requirements.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      2. Will my website be mobile-friendly?
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Absolutely! We use responsive web design techniques to ensure your website will look great and function properly on any device, whether it's a desktop, tablet, or smartphone.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      3. Will my website be search engine optimized (SEO)?
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Yes, we will optimize your website for search engines as part of the development process. This includes using proper HTML tags, optimizing images, and ensuring fast load times. For more advanced SEO strategies (like keyword research and backlink building), we offer specialized SEO services.</div>
    </div>
  </div>
  
  <div className="accordion" id="accordionPanelsStayOpenExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
      4. Can I update my website myself after it’s built?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div className="accordion-body">
      Yes, if we build your website using a CMS, you'll be able to easily update your content, such as adding new blog posts, images, or products. We provide training and support to ensure you are comfortable managing your website.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
     5. Will you provide ongoing support after my website is launched?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div className="accordion-body">
      Yes, we offer ongoing support to handle any technical issues, content updates, or enhancements. We also provide maintenance packages to ensure your website remains secure, up-to-date, and functional.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      6. Can you integrate third-party tools (like payment gateways, CRMs, etc.) into my website?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
      <div className="accordion-body">
      Yes, we can integrate various third-party tools, such as payment systems (PayPal, Stripe), customer relationship management (CRM) software, email marketing services, and analytics tools into your website to help streamline your business operations
      </div>
    </div>
  </div>
</div>

</div>

</>
)
}
