export async function createRecord(collection,payload){
    let response = await fetch(`https://pgc.onrender.com/api/${collection}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "authorization":localStorage.getItem("token"),
          },
          body: JSON.stringify({ ...payload }),
        });
        return await response.json();
  }
  
  // ****** form data API call (image Pdf )
  export async function createRecordformData(collection,payload){
    let response = await fetch(`https://pgc.onrender.com/api/${collection}`, {
          method: "POST",
          headers: {
            "authorization":localStorage.getItem("token"),
          },
          body:payload 
        });
        return await response.json();
  }
  
  export async function getRecord(collection){
      let response = await fetch(`https://pgc.onrender.com/api/${collection}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "authorization":localStorage.getItem("token"),
          }
        });
        return await response.json();
  }
  
  export async function updateRecord(collection,payload){

      let response = await fetch(`https://pgc.onrender.com/api/${collection}/${payload._id}`, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            "authorization":localStorage.getItem("token"),
          },
          body: JSON.stringify({ ...payload }),
        });
        return await response.json();
  }
  
  // ****** form data update API call
  export async function updateRecordFormData(collection,payload){
    let response = await fetch(`https://pgc.onrender.com/api/${collection}/${payload.get('_id')}`, {
        method: "PUT",
        headers: {
          "authorization":localStorage.getItem("token"),
        },
        body: payload
      });
      return await response.json();
  }
  
  export async function deleteRecord(collection, payload){
      let response = await fetch(`https://pgc.onrender.com/api/${collection}/${payload._id}`, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            "authorization":localStorage.getItem("token"),
          },
          body: JSON.stringify({...payload }),
        });
        return await response.json();
  }