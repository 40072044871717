import React, { useEffect, useState } from 'react'
import { DataGrid} from "@mui/x-data-grid";
import { Link, useParams } from 'react-router-dom'
import AdminHome from '../AdminHome'

import {getClient,deleteClient} from "../../../Store/ActionCreaters/ClientActionCreaters"
import { useSelector, useDispatch } from 'react-redux';

export default function AdminClient() {
    var {_id}= useParams()
    
    let [data,setData]=useState([])
    const columns = [
        { field: "_id", headerName: "ID", width: 150 },
        { field: "name", headerName: "Name", width: 350 },
        { field: "address", headerName: "Address", width:350 },
        { field: "pic", headerName: "Pic", width: 200 , renderCell:({row})=>
            <a href={`https://pgc.onrender.com/${row.pic}`} target='_blank' rel="noreferrer" >
            <img src={`https://pgc.onrender.com/${row.pic}`} height={50} width={50} alt=" no-image" /> </a>
            },

        { field: "edit", headerName: "Edit", width: 100 , renderCell:({row})=><Link to={`/admin/client/update/${row._id}`} className="btn btn-primary"><i className="bi bi-pencil-square text-light fs-5" ></i></Link>},
        { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
      ];

let dispatch =useDispatch()
let ClientStateData =useSelector((state)=>state.ClientStateData)

    // delete data from database
 function deleteData(_id) {
        if(window.confirm("Are You Sure Delete this Item :")){
        dispatch(deleteClient({_id:_id}))
        getAPIData()
    }
    }



    // getAPI Data
 function getAPIData(){
   dispatch(getClient())
    if(ClientStateData.length)
        setData(ClientStateData)
    else
    setData([])
 }
 useEffect(()=>{
  getAPIData()
 },[ClientStateData ?.length])
  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'>Admin Client Page</h2>
  <AdminHome/>
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>Our Clients Table <Link to="/admin/client/create"> <i className="bi bi-plus fs-2 text-center text-light  float-end" ></i></Link></h2>
    { ClientStateData?.length?
       <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  getRowId={(row) =>row._id} 
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10,20,50,100]}
                  checkboxSelection={false}
                />
       </div>
       : <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> Client Table Record Not Found</h4>
    }
        </div>
        </div>
</div>
        </>
  )
}
