import { put,takeEvery } from "redux-saga/effects";
import { CREATE_QUERY, CREATE_QUERY_RED, DELETE_QUERY, DELETE_QUERY_RED, GET_QUERY, GET_QUERY_RED } from "../../Constant";

import { createRecord, deleteRecord, getRecord,  } from './Services'


function* createSaga(action){
    let response= yield createRecord("contactus",action.payload)
    yield put({type:CREATE_QUERY_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("contactus")
    yield put({type:GET_QUERY_RED, payload:response.data})
}

// function* updateSaga(action){
//      yield updateRecord("contactus",action.payload)
//     yield put({type:UPDATE_QUERY_RED , payload:action.payload})
// }

function* deleteSaga(action){
    yield deleteRecord("contactus",action.payload)
    yield put({type:DELETE_QUERY_RED, payload:action.payload})
}

export default function* querySagas(){
    yield takeEvery (CREATE_QUERY, createSaga)
    yield takeEvery(GET_QUERY, getSaga)
    // yield takeEvery(UPDATE_QUERY,updateSaga)
    yield takeEvery(DELETE_QUERY,deleteSaga)
}