import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLicense } from "../../Store/ActionCreaters/LicenseActionCreaters";

export default function Service() {
 let[license,setLicense]=useState([])
 let LicenseStateData = useSelector((x)=>x.LicenseStateData)
 let dispatch = useDispatch()

 const goToTop =()=>{
  window.scrollTo({top:0,left:0 ,behavior:"smooth"})
}

//  API data call
// async function getAPIData(){
//   let response = await fetch("/api/license",{
//     method:"GET",
//     headers:{
//       "content-type":"application/json"
//     }
//   })
//   response =await response.json()
//   setLicense(response.data)
// }
useEffect(()=>{
  dispatch(getLicense())
  if(LicenseStateData.length){
    setLicense(LicenseStateData)
  }
  else
  setLicense([])
},[LicenseStateData.length])
  return (
    <>
      {/* <div className="container-xxl py-5"> */}

      <div className="container-fluid  ">
        <h1 className="my-1 p-3 text-center">
          Explore Our <span style={{ color: "#F19A17" }}>License Services</span>
        </h1>
        <p className="mx-4 service-para" style={{textAlign:" justify"}}>
        Discover hassle-free licensing with our all-in-one service! From FSSAI and DPCC/Fire, Excise licenses to MCD registrations, GST filing, and MSME recognition, we cover every compliance need. We also handle IEC codes for import/export, Delhi Police certificates, e-district services, and intellectual property registrations like trademarks and patents. Let us 
        streamline your licensing, so you can focus on growing your business.
        </p>
        <div className="row ">
          {license.slice(0,8).map((item, index) => {
            return (
              <div className=" col-lg-3 col-md-4 col-sm-6  wow fadeInUp col-12 " key={index}>
                <div
                  className="card my-2 my-4 mx-auto mx-2 licenseCard"
                  style={{ width: "17rem" }}
                >
                  <img
                    src={`https://pgc.onrender.com/${item?.pic}`} 
                    className="card-img-top"
                    alt="..."style={{height:"10rem", objectFit:"fill"}}
                  />
                  <div className="card-body">
                   <h4> <Link to={`/services/${item._id}`} onClick={goToTop} > {item.name}</Link></h4>
                    <p className="card-text">
                     {item.bio}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
 <Link to="/allservices-page" className=" text-center fs-5 view-more" style={{ width:"15rem", margin:"auto", }} onClick={goToTop} > View More </Link>

        </div>
        {/* <Service title={"Our All Services"}/> */}
    
      </div>
    </>
  );
}
