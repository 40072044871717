import {  GET_QUERY, DELETE_QUERY,CREATE_QUERY } from "../Constant";

export function deleteQuery(data){
    return{
        type:DELETE_QUERY,
        payload: data
    }
}

export function getQuery(){
    return{
        type:GET_QUERY,
    }
}

export function createQuery(data){
    return{
        type:CREATE_QUERY,
        payload: data
    }
}

// export function deleteLicense(data){
//     return{
//         type:DELETE_LICENSE,
//         payload: data
//     }
// }