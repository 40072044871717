import React, { useEffect } from "react";
import { useState } from "react";
import FormValidation from "../Validation/FormValidation";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import {getQuery ,createQuery,} from "../../Store/ActionCreaters/QueryActionCreaters"
export default function QueryForm() {
  let [show,setShow]=useState()
let dispatch = useDispatch()
let QueryStateData = useSelector((x)=>x.QueryStateData)
  let [data, setData] = useState({
    name:"",
    email:"",
    phone:"",
    message:"",
    select:""
  });

  let [errorMassage, setErrorMassage] = useState({
    name: "Name Field is Mendatory",
    email: "Email Field is Mendatory",
    phone: "Phone Field is Mendatory",
  });



  function getInputData(e) {
    var { name, value } = e.target;
    if(name ==="name"|| name === "phone"|| name ==="email"){
     setErrorMassage((old)=>{
      return{
        ...old,
        [name]:FormValidation(e)
      }
     })
    }
    setData((old)=>{
      return{
        ...old,
        [name]:value
      }
    })
  }
  
 function postData(e) {
    e.preventDefault();
    let error = Object.values(errorMassage).find((x)=>x.length > 0)
    if(error){
      setShow(true)
    }
    else{
      let item =QueryStateData.find((x)=>x.phone === data.phone)
      if(item){
        setShow(true)
        setErrorMassage((old)=>{
          return{
            ...old,
            phone:"Phone Number Is Already Exist"
          }
        })
      }
      else{
      dispatch(createQuery({...data}))
        toast.success('Thank You !! Your Form Successfully  Submit')

          setTimeout(() => {
              // console.log("Condition is true, and timeout has completed!");
              window.location.reload()
          }, 1000); // Timeout after 3 seconds (3000 milliseconds)
   
      }
    }
  }
  useEffect(()=>{
    (()=>{
        dispatch(getQuery())
    })()
},[QueryStateData.length])
  return (
    <>
       <div className=" py-4 query-form ">
        <div className="row">
          <div className="col-md-8 col-sm-9 col-11 m-auto">
          <h5 className="text-center p-2 text-dark">
            Get a Free Consultation Today!!
          </h5>
          
      < form  onSubmit={postData} autocomplete="on">
        <div className="row ">
        <div className=" col-md-6 mb-3 ">
          <input type="text"  name="name"  autocomplete="name"   onChange={getInputData} className="form-control" id="exampleFormControlInput1" placeholder="Name*" />
       { show && errorMassage.name ? <p className="text-danger text-capitalize">{errorMassage.name}</p>:""}
        </div>
        <div className=" col-md-6  mb-3 ">
          <input type="email"  name="email" autocomplete="email"  onChange={getInputData} className="form-control" id="exampleFormControlInput2" placeholder="Email*" />
       { show && errorMassage.email ? <p className="text-danger text-capitalize">{errorMassage.email}</p>:""}
       </div>
        </div>
        <div className="row ">
        <div className=" col-md-6  mb-3 ">
          <input type="number"  name="phone"  autocomplete="phone"  onChange={getInputData} className="form-control" id="exampleFormControlInput3" placeholder="Phone*" />
          { show && errorMassage.phone ? <p className="text-danger text-capitalize">{errorMassage.phone}</p>:""}
          </div>
        <div className=" col-md-6  mb-3 ">
        <select className="form-select" name="select" onChange={getInputData} aria-label="Default select example">
           <option selected="No Select" >Select License Type</option>
            <option value="FSSAI Registration" >FSSAI Registration</option>
             <option value="DPCC / Fire Registration" >DPCC / Fire Registration</option>
              <option value="Delhi Police Online" >Delhi Police Online</option>
              <option value="MCD All License  Registration" >MCD All License  Registration</option>
              <option value="MSME(UDYAM) Online Registration" >MSME(UDYAM) Online Registration</option>
              <option value="RCMC Online Registration" >RCMC Online Registration</option>
              <option value="Other" >Other</option>
        </select>
        </div>
        
    
         <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" name="message" onChange={getInputData} id="message" style={{height: "150px"}}></textarea>
                                            <label htmlFor="message">Message.........</label>
                                        </div>
                                    </div>

        </div>
        <div className="m-2 ">
          <button type="submit" className="btn btn-success w-100" style={{color:"white", fontWeight:"600"}}>SEND MESSAGE</button>

        </div>
      </form>
      </div> 
      </div>
      </div>
    </>
  );
}
