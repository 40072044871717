import React from 'react'
import "../../assets/css/style.css"


export default function About() {
  return (
    <>
      <div className="container-fluid">
        
           <div className="row">
           {/*left side  */}
        <div className="col-md-6">
          <h1 className='text-1'><sup>_____  </sup>About  <sup>_____</sup></h1>
          {/* about-pgc-1 */}
          <div className='about-pgc-text'>
           <h1 className='text-2'><span className='text-4'> Welcome to</span> PERFECT GLOBAL CONSULTANCY</h1>
            <p className='text-3' style={{textAlign:" justify"}}>Anup Khanna, founder and owner of Perfect Global Consultancy, celebrates 20 years as a leader in the licensing industry & web development industry, renowned for his expertise and client-first approach. With a mission to simplify complex regulatory processes, Anup has been the
               go-to expert for businesses navigating FSSAI, GST, MCD, DPCC, Excise and other critical licenses. We are passionate about building innovative, functional, and visually stunning websites that help businesses grow . Under his guidance, Perfect Global Consultancy has become a trusted partner for clients seeking seamless and reliable compliance solutions. Anup’s two decades of dedication,
                industry knowledge, and commitment to excellence empower businesses to thrive, setting the gold standard in licensing & web development consultancy.</p>
          </div>
          {/* about-pgc-2 */}

         <div className='about-cart'>
         <div className="about-item">
            <img src="./img/services.png" alt="" height={100} width={100} />
            <h1>100+</h1>
            <p>Services</p>
            </div>
            <div className="about-item">
             <img src="./img/clients.png" alt="" height={100} width={100}  />
              <h1>1000+</h1>
              <p>Clients</p>
             </div>
         </div>
   </div>

        {/*right side  */}

        <div className="col-md-6">
          <div className="right-side">
          <div className="about-license-pic">
     <img src="./img/fssailogo2.png" className='rounded-3' alt="" />
     </div>

  {/* years of company */}
  <div className="box">
          <h1 className='text-center'>20+ Years of Service Experience</h1>
         </div>
          </div>
    
       
        </div>

        
        </div>
      </div>
    
    </>
  )
}
