import React from 'react'
import { About,  } from './partials'
import { Helmet } from  'react-helmet'


export default function AboutUs() {
  return (
    <>  <Helmet>
    <title>PGC | About US </title>
    <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
    PGC last 15 years  experince of license providing . so pls visit my website. " />
    <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
    eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
    delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
  </Helmet>
  <About />

    </>
  )
}
