import React, { useEffect, useState } from 'react'
import { DataGrid} from "@mui/x-data-grid";
import { Link } from 'react-router-dom'
import AdminHome from '../AdminHome'

import {getLicense, deleteLicense} from "../../../Store/ActionCreaters/LicenseActionCreaters"
import { useSelector, useDispatch } from 'react-redux';

export default function AdminLicense() {
    let [data,setData]=useState([])
    const columns = [
        { field: "_id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Name", width: 150 },
        { field: "aboutLicense", headerName: "About License Heading", width:200 },
        { field: "aboutLicenseDetails", headerName: "About License Details", width:200 },
        { field: "licenseHeading", headerName: "License Heading", width: 200 },
        { field: "licenseHeadingDetails", headerName: "License Heading Details", width: 200 },
        { field: "pic", headerName: "Pic", width: 100 , renderCell:({row})=>
            <a href={`https://pgc.onrender.com/${row.pic}`} target='_blank' rel="noreferrer">
            <img src={`https://pgc.onrender.com/${row.pic}`} height={50} width={50} alt=" no imag" /> </a>
            },
       
        { field: "edit", headerName: "Edit", width: 100 , renderCell:({row})=><Link to={`/admin/license/update/${row._id}`} className="btn btn-primary"><i className="bi bi-pencil-square text-light fs-5" ></i></Link>},
        { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
      ];

let dispatch =useDispatch()
let LicenseStateData =useSelector((state)=>state.LicenseStateData)

    // delete data from database
 function deleteData(_id) {
        if(window.confirm("Are You Sure Delete this Item :")){
      
        dispatch(deleteLicense({_id:_id}))
        getAPIData()
    }
    }



    // getAPI Data
    function getAPIData(){
       
        dispatch(getLicense())
    if(LicenseStateData.length){
        setData(LicenseStateData)
      } 
      else
      setData([])
 }


    useEffect(()=>{
         getAPIData()
    },[LicenseStateData.length])

  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'>Admin License Page</h2>
  <AdminHome/>
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>License Table <Link to="/admin/license/create"> <i className="bi bi-plus fs-2 text-center text-light  float-end" ></i></Link></h2>

       {
        LicenseStateData.length ?
        <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={data}
                  getRowId={(row) => row._id} 
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10,20,50,100]}
                  checkboxSelection={false}
                />
         </div>
          : <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> License Table  Record  Not Found</h4>

       } 
        </div>
        </div>
</div>
        </>
  )
}
