import React, { useState } from 'react'
import {  Link, useNavigate } from 'react-router-dom'

export default function ForgetPassword1() {
  let[otp,setOtp]=useState("")

  let [errorMessage,setErrorMessage]=useState({

    // password:"Password Field Is Required"
  })
  let [show,setShow]=useState(false)
  let navigate =useNavigate()
 
 function getInputData(e){
  setShow(false)
  setErrorMessage("")
    setOtp( e.target.value)
 }

 async function postData(e){
  e.preventDefault()
    let response = await fetch("https://pgc.onrender.com/api/admin/forget-password-2",{
       method:"POST",
       headers:{
        "content-type":"application/json",
        "authorization":localStorage.getItem("token")
       },
       body:JSON.stringify({username:localStorage.getItem("password-reset-admin"),otp:otp})
    })
    response = await response.json()
  
    if(response.result === "Done"){ 
      navigate("/forget-password2")  
    }
  else{
    setShow(true)
    setErrorMessage(response.reason)
  }
  
 }
  
 
  return (
    <>
  <div className="container-fluid my-3">
    <div className="row">
        <div className="col-md-6 m-auto">
        <h5 className='bg-success rounded-3 p-2 text-light text-center'>
        <Link to="/forget-password"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link>
          
          Forget Your Password</h5>
        <form onSubmit={postData}>
           <div className="row">
            <div className="mb-3">
                <label className='mx-3'>OTP :</label>
                <input type="number" name="otp" onChange={getInputData} placeholder='Enter Your OTP' className='form-control border-2'/>
                {show && errorMessage ? <p className='text-danger text-capitalize'>{errorMessage}</p>:""}
            </div>
            </div>

          <div className="row">
            <div className="mb-3" >
             <button type="submit"  className='btn btn-success login-bg text-light text-center p-2 w-100'>Submit</button>
          {/* <Link to={"/forget-password"} className='float-start mx-0' > ForgetPassword?</Link> */}
          {/* <Link to={"/admin/create/profile"} className='float-end mx-0' > CreateYourAccount?</Link> */}
            </div>
            </div>

        </form>
        </div>
    </div>
  </div>
    </>
  )
}
