import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormValidation from "./Validation/FormValidation";

import {getQuery,createQuery} from "../Store/ActionCreaters/QueryActionCreaters"
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export default function ContactUs() {
    let [show,setShow]=useState(false)
    let navigate =useNavigate()
    let [data,setData]=useState({
        name:"",
        email:"",
        phone:"",
        message:"",
        select:""
    })
    let [errorMessage,setErrorMessage]=useState({
        name:"Name Field Is Required ",
        email:"Email Field Is Required",
        phone:"Phone Field Is Required",
    })

    let dispatch =useDispatch()
    let QueryStateData = useSelector((x)=>x.QueryStateData)
    // get inputData
     function getInputData(e){
        let {name,value}= e.target;
    if(name ==="name" || name==="email" || name ==="phone"){
       setErrorMessage((old)=>{
        return{
            ...old,
            [name]:FormValidation(e)
        }
       })
    }
     setData((old)=>{
        return{
            ...old,
            [name]:value
        }
       })
        
    }

    // post data
   function postData(e){
        e.preventDefault()
        let error = Object.values(errorMessage).find((x)=>x?.length >0)
        if(error){
            setShow(true)
        }
        else{
        // let response= await fetch("http://localhost:8000/query",{
        //     method:"GET",
        //     headers:{
        //         "content-type":"application/json"
        //     }
        // })   
        // response =await response.json()
        let item = QueryStateData.find((x)=>x.phone === data.phone) 
        if(item){
            setShow(true)
           setErrorMessage((old)=>{
            return{
                ...old,
                phone : "Your Phone Number Is Already Exist"
            }
           })

        }    
        else{
            dispatch(createQuery({...data}))
            navigate("/")
        }    
        }
    }

    useEffect(()=>{
        (()=>{
            dispatch(getQuery())
        })()
    },[QueryStateData.length])
  return (
    <>
{/* <!-- Contact Start --> */}
<Helmet>
      <title>PGC | Contact US </title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>

        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title text-center  text-uppercase fs-1" style={{color:"#F19A17"}}><sup> _____ </sup>Contact Us <sup>_____</sup></h6>
                    <h1 className="mb-5 fs-3"><span className=" text-uppercase " style={{color:"#F19A17"}}>Contact</span> For Any Query</h1>
                </div>
                <div className="row g-4 ">
                    <div className="col-12">
                        <div className="row gy-4 ">
                            <div className="col-md-3 ">
                                <h6 className="section-title text-start text-uppercase" style={{color:"#F19A17"}}>Address</h6>
                                <p><i className="bi bi-geo-alt-fill  me-2" style={{color:"#F19A17"}}></i>B-4, First Floor, Prehlad Market, DB Gupta Rd, Karol Bagh, New Delhi, 110005</p>
                            </div>
                            <div className="col-md-3">
                                <h6 className="section-title text-start  text-uppercase" style={{color:"#F19A17"}}>Phone</h6>
                                <p><i className="bi bi-telephone-fill me-2 " style={{color:"#F19A17"}}></i>
                                <Link className="text-dark me-2" target="_blank" to="tel:+919971522879 ">+91 9971522879 </Link>   
                                
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h6 className="section-title text-start  text-uppercase" style={{color:"#F19A17"}}>Email </h6>
                                <p><i className="bi bi-envelope-fill me-2 " style={{color:"#F19A17"}}></i>
                                <Link className="text-dark"
                                    target="_blank"   to="mailto:infoperfectglobalconsultancy@gmail.com">infoperfectglobalconsultancy@gmail.com</Link>
                                </p>
                            </div>

                            <div className="col-md-3">
                                <h6 className="section-title text-start  text-uppercase" style={{color:"#F19A17"}}>WhatApp </h6>
                                <p><i className="bi bi-whatsapp  me-2" style={{color:"#F19A17"}}></i>
                                <Link className="text-dark" target="_blank"  to="https://wa.me/+919971522879">+91
                                        9971522879</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                        {/* <iframe className="position-relative rounded w-100 h-100" title="con-iframe"
                            src="https://www.google.com/maps/embed/v1/place?q=B-4,+First+Floor,+Prehlad+Market,+DB+Gupta+Rd,+Karol+Bagh,+New+Delhi,+110005&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                            style={{minHeight: "350px", border:"0"}} 
                            tabIndex="0"/> */}
                            <iframe style={{height:"100%",width:"100%", border:"0",}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=PERFECT+GLOBAL+CONSULTANCY&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
                    </div>
                    <div className="col-md-6">
                         <div className="wow fadeInUp" data-wow-delay="0.2s">
                            <form onSubmit={postData}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="name"  onChange={getInputData} id="name" placeholder="Your Name"/>
                                            <label htmlFor="name"> Name*</label>
                                            {show && errorMessage.name?<p className="text-danger text-capitalize">{errorMessage.name}</p>:""}


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" name="email" onChange={getInputData}  id="email" placeholder="Your Email"/>
                                            <label htmlFor="email">Email*</label>
                                            {show && errorMessage.email ?<p className="text-danger text-capitalize">{errorMessage.email}</p>:""}



                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input type="number" className="form-control" onChange={getInputData} name="phone"  id="subject" placeholder="Phone" />
                                            <label htmlFor="subject">Phone*</label>
                                            {show && errorMessage.phone ?<p className="text-danger text-capitalize">{errorMessage.phone}</p>:""}
                                        </div>
                                    </div>
                                    <div className=" col-6 ">
                                    <div className="form-floating">
                                     <select className="form-select" name="select" onChange={getInputData} aria-label="Default select example">
                                     <option selected="No Select" >Select License Type</option>
                                       <option value="FSSAI Registration" >FSSAI Registration</option>
                                        <option value="DPCC / Fire Registration" >DPCC / Fire Registration</option>
                                        <option value="Delhi Police Online" >Delhi Police Online</option>
                                        <option value="MCD All License  Registration" >MCD All License  Registration</option>
                                        <option value="MSME(UDYAM) Online Registration" >MSME(UDYAM) Online Registration</option>
                                        <option value="RCMC Online Registration" >RCMC Online Registration</option>
                                        <option value="Other" >Other</option>
                                     </select>
                                     </div>
                                   </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" name="message" onChange={getInputData} id="message" style={{height: "150px"}}></textarea>
                                            <label htmlFor="message">Message.........</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-warning w-100 py-3 fs-5"  type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div> 
                    
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Contact End --> */}

    </>
  );
}
