import React, { useEffect, useState } from 'react'
import AdminHome from '../AdminHome'
import { DataGrid} from "@mui/x-data-grid";
// import { Link } from 'react-router-dom';
 import { getWebQuery,deleteWebQuery } from '../../../Store/ActionCreaters/WebQueryActionCreaters';
import { useDispatch, useSelector } from 'react-redux';

export default function AdminWebQuery() {
    let[data,setData]=useState([])
    const columns = [
        { field: "_id", headerName: "ID", width: 200 },
     
        { field: "name", headerName: "Name", width: 300 },
        { field: "phone", headerName: "Phone", width:300 },
        { field: "email", headerName: "Email", width: 450 },
        { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
      ];

      let dispatch = useDispatch()
      let WebQueryStateData = useSelector((state)=>state.WebQueryStateData)

  function deleteData(_id) {
           if (window.confirm("You Are Sure to Delete This Item : ")) {
          
            dispatch(deleteWebQuery({_id:_id}))
            getAPIData()

           }
 }

// get api data 
 function getAPIData(){
    dispatch(getWebQuery())
    if(WebQueryStateData.length){
        setData(WebQueryStateData)
    }
    else
    setData([])
}

useEffect(()=>{
    getAPIData()
},[WebQueryStateData.length])
  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'>Admin  Website Query Page</h2>
    <AdminHome/>
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>All Website Query Table</h2>
    { WebQueryStateData?.length?
          <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  getRowId={(row) => row._id} 
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10,20,50,100]}
                  checkboxSelection={false}
                />
              </div>
              : <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> WebSite Query Table Record Not Found</h4>
    }
        </div>
        </div>
</div>
        </>
  )
}
