import { put,takeEvery } from "redux-saga/effects";
import { CREATE_LICENSE, CREATE_LICENSE_RED, DELETE_LICENSE, DELETE_LICENSE_RED, GET_LICENSE, GET_LICENSE_RED, UPDATE_LICENSE, UPDATE_LICENSE_RED } from "../../Constant";

import {  createRecordformData, deleteRecord, getRecord, updateRecordFormData } from './Services'


function* createSaga(action){
    // let response= yield createRecord("license",action.payload)
    let response= yield createRecordformData ("license",action.payload)
    yield put({type:CREATE_LICENSE_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("license")
    yield put({type:GET_LICENSE_RED, payload:response.data})
}

function* updateSaga(action){
    let response= yield updateRecordFormData("license",action.payload)
    yield put({type:UPDATE_LICENSE_RED , payload:response.data})
}

function* deleteSaga(action){
    yield deleteRecord("license",action.payload)
    yield put({type:DELETE_LICENSE_RED, payload:action.payload})
}

export default function* licenseSagas(){
    yield takeEvery (CREATE_LICENSE, createSaga)
    yield takeEvery(GET_LICENSE, getSaga)
    yield takeEvery(UPDATE_LICENSE,updateSaga)
    yield takeEvery(DELETE_LICENSE,deleteSaga)
}