import React from 'react'

export default function BannerWebSite() {
  return (
  <>
  {/* <div className="container-fluid"> */}
    <div className="web-banner">
    <img src="./img/web151.png" alt=""  />

    </div>
  {/* </div> */}
  </>
)
}
