import { CREATE_WEBQUERY_RED, DELETE_WEBQUERY_RED, GET_WEBQUERY_RED,  } from "../Constant";

export default function WebQueryReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_WEBQUERY_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_WEBQUERY_RED:
            return action.payload

        // case UPDATE_WEBQUERY_RED:
            // index=state.findIndex((x)=>x.id === action.payload.id)
            // state[index].name=action.payload.name
            // state[index].pic=action.payload.pic
            // state[index].address=action.payload.address
            // return state
        
        case DELETE_WEBQUERY_RED:
            return state.filter((x)=> x._id !== action.payload._id)

        
        default :
            return state
    }
}