import React, { useEffect, useState } from 'react'
import QueryForm from './partials/QueryForm'
import Testimonials from './partials/Testimonials'
import OurServices from './partials/OurServices'
import {getClient} from "../Store/ActionCreaters/ClientActionCreaters"

import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

export default function AllClientPage() {
  let [data,setData]= useState([])
  let ClientStateData = useSelector((x)=>x.ClientStateData)
  let dispatch =useDispatch()

  
  function getAPIData(){
   dispatch(getClient())
   if(ClientStateData.length){
     setData(ClientStateData)
   }
   else setData([])
  }
 
  useEffect(()=>{
    getAPIData()
  },[ClientStateData.length])
  return (
    <>
      <Helmet>
      <title>PGC | All Clients </title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>
    <div className="container-fluid my-2">
      <h2 className='text-center'>Our All <span style={{color:"#F19A17"}}> Clients</span> </h2>
      <div className="row g-4">
        {
          data ?.map((item, index)=>{
            return (
              <div className="col-lg-3  col-md-6 wow fadeInUp" key={index} data-wow-delay="0.5s">
                         <div
                  className="card my-2 my-4 mx-2 mx-auto licenseCard"
                  style={{ width: "18rem" }}
                >
                  <img
                    src={`https://pgc.onrender.com/${item.pic}`}
                    className="card-img-top"
                    alt="..."style={{height:"10rem", objectFit:"fill"}}
                  />
                  <div className="card-body">
                   <h5> {item.name}</h5>
                    <p className="card-text">
                    <i class="bi bi-geo-alt-fill"></i>  {item.address}
                    </p>
                  </div>
                </div>  
                 </div>
            )
          })
        }
      </div>
      <QueryForm/>
      <Testimonials/>
      <OurServices/>
    </div>
    </>
  )
}
