import {  GET_WEBQUERY, DELETE_WEBQUERY,CREATE_WEBQUERY } from "../Constant";

export function deleteWebQuery(data){
    return{
        type:DELETE_WEBQUERY,
        payload: data
    }
}

export function getWebQuery(){
    return{
        type:GET_WEBQUERY,
    }
}

export function createWebQuery(data){
    return{
        type:CREATE_WEBQUERY,
        payload: data
    }
}

// export function deleteLicense(data){
//     return{
//         type:DELETE_LICENSE,
//         payload: data
//     }
// }