import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import QueryForm from './partials/QueryForm'
import Testimonials from './partials/Testimonials'
import OurServices from './partials/OurServices'
import { useDispatch, useSelector } from 'react-redux';
import { getLicense } from '../Store/ActionCreaters/LicenseActionCreaters';
import { Helmet } from 'react-helmet';

export default function AllServicesPage() {
 let [data,setData]= useState([])
 let LicenseStateData = useSelector((x)=>x.LicenseStateData)
 let dispatch =useDispatch()

 const goToTop =()=>{
  window.scrollTo({top:0,left:0 ,behavior:"smooth"})
}

 function getAPIData(){
  dispatch(getLicense())
  if(LicenseStateData.length){
    setData(LicenseStateData)
  }
  else setData([])
 }

 useEffect(()=>{
   getAPIData()
 },[LicenseStateData.length])

  return (
 <>
   <Helmet>
      <title>PGC | All Services  </title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>
   <div className="container-fluid  ">
        <h1 className="my-1 p-3 text-center">
          Explore Our <span style={{ color: "#F19A17" }}>All Services</span>
        </h1>
       
        <marquee className="blink" behavior="alternate"  direction="right" >
        {/* <div className="scrolling-container"> */}
          <a href="https://foscos.fssai.gov.in/"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer">FSSAI License |</a>
          <a href="https://www.gst.gov.in/"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> G.S.T Registration & Filling |</a>
          <a href="https://udyamregistration.gov.in/" className='me-2' target="_blank" rel="noopener noreferrer"> MSME Online |</a>
          <a href="https://dpcc.delhigovt.nic.in/#gsc.tab=0"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> DPCC Online License |</a>
          <a href="https://ipindia.gov.in/index.htm"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer">TradeMark Registration  |</a>
          <a href="https://gem.gov.in/"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> GeM Registration|</a>
          </marquee>
      
          <marquee className="blink" behavior="alternate" direction="left">
          <a href="https://mcdonline.nic.in/portal"  className="scrolling-tex " target="_blank" rel="noopener noreferrer"> MCD All License |</a>
          <a href="https://ipindia.gov.in/index.htm"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> Patent Registration|</a>
          <a href="https://www.dgft.gov.in/CP/"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> Import & Export (IEC code) |</a>
          <a href="https://www.dgft.gov.in/CP/"  className="scrolling-text me-2" target="_blank" rel="noopener noreferrer"> RCMC Online Registration |</a>

        </marquee>
        {/* </div> */}

        <div className="row mt-2">
          {LicenseStateData?.map((item, index) => {
            return (
              <div className=" col-lg-3 col-md-4 col-sm-6  wow fadeInUp col-12 " key={index}>
                <div
                  className="card my-2 my-4 mx-2 mx-auto licenseCard"
                  style={{ width: "18rem" }}
                >
                  <img
                    src={`https://pgc.onrender.com/${item.pic}`} height={"110rem"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                   <h4> <Link to={`/services/${item._id}`} onClick={goToTop}> {item.name}</Link></h4>
                    <p className="card-text me-2">
                     {/* {item.getLicense} */}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
 
 </div>
<QueryForm/>
<Testimonials/>
<OurServices/>
 </div>
 </>
  )
}
