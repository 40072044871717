import { CREATE_TESTIMONIAL_RED, DELETE_TESTIMONIAL_RED, GET_TESTIMONIAL_RED, UPDATE_TESTIMONIAL_RED } from "../Constant";

export default function TestimonialReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_TESTIMONIAL_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_TESTIMONIAL_RED:
            return action.payload

        // case UPDATE_LICENSE_RED:
            // index=state.findIndex((x)=>x.id === action.payload.id)
            // state[index].name=action.payload.name
            // state[index].pic=action.payload.pic
            // state[index].aboutLicense=action.payload.aboutLicense
            // state[index].applyLicenseName=action.payload.applyLicenseName
            // state[index].applyLicenseDetails=action.payload.applyLicenseDetails
            // state[index].getLicenseName=action.payload.getLicenseName
            // state[index].getLicenseDetails=action.payload.getLicenseDetails
            // return state
        
        case DELETE_TESTIMONIAL_RED:
            return state.filter((x)=> x.id !== action.payload._id)

        
        default :
            return state
    }
}