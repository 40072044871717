import React, { useEffect, useState } from 'react'
import SideBar from '../../partials/SideBar'
import FormValidation from '../../Validation/FormValidation'
import { Link, useNavigate, useParams } from 'react-router-dom'
import imageValidation from '../../Validation/imageValidation'
import { useDispatch, useSelector } from 'react-redux'

import { getLicense, updateLicense } from '../../../Store/ActionCreaters/LicenseActionCreaters'

export default function AdminUpdateLicense() {
 let [allData,setAllData]= useState([])
  let navigate =useNavigate()
  var {_id}= useParams()
  
  let [data,setData]=useState({
    name:"",
    pic:"",
    aboutLicense:"",
    licenseHeading:"",
    licenseHeadingDetails:"",
    requiredDocumentHeading:"",
    document1:"",
    document2:"",
    document3:"",
    document4:"",
    document5:"",
    document6:"",
    document7:"",
    document8:"",
    aboutLicenseDetails:"",
  })
  let [errorMessage,setErrorMessage] =useState({
    name:"",
   pic:"",
   aboutLicense:"",
   licenseHeading:"",
   licenseHeadingDetails:"",
   aboutLicenseDetails:""
  
  })
  let [show,setShow]=useState(false)
  let dispatch = useDispatch()
  let  LicenseStateData = useSelector((state)=>state.LicenseStateData)

// getInputdata
function getInputData(e){
  let name = e.target.name;
  let value  = e.target.files ? e.target.files[0]:e.target.value
  if(name ==="name" || name === "pic" || name === "aboutLicense" || name === "licenseHeading" || name === "licenseHeadingDetails" ){
  setErrorMessage((old)=>{
      return{
        ...old,
        [name]:e.target.files ? imageValidation(e): FormValidation(e)
      }
    })

  }
  setData((old)=>{
   return{
    ...old,
     [name]:value
 }})
 }

//  post data
function postData(e){
  e.preventDefault()
  let error= Object.values(errorMessage).find((x)=>x.length >0)
  if(error){
    setShow(true)
  }
  else{
    let item = allData.find((x)=>x.name?.toLowerCase() === data.name.toLowerCase() && x._id !== _id)
    if(item ){
      setShow(true)
      setErrorMessage((old)=>{
        return{
          ...old,
          'name':"Sorry !! License Name Is Already Exist In DataBase !!"
        }
      })
    }
    else{
      let formData = new FormData()
      formData.append("_id", data._id)
      formData.append("name", data.name)
      formData.append("pic", data.pic)
      formData.append("aboutLicense", data.aboutLicense)
      formData.append("aboutLicenseDetails", data.aboutLicenseDetails)
      formData.append("licenseHeading", data.licenseHeading)
      formData.append("licenseHeadingDetails", data.licenseHeadingDetails)
      formData.append("requiredDocumentHeading", data.requiredDocumentHeading)
      formData.append("document1", data.document1)
      formData.append("document2", data.document2)
      formData.append("document3", data.document3)
      formData.append("document4", data.document4)
      formData.append("document5", data.document5)
      formData.append("document6", data.document6)
      formData.append("document7", data.document7)
      formData.append("document8", data.document8)
     dispatch(updateLicense(formData))
      navigate("/admin/license")
    }
  }
 }


useEffect(()=>{
  (()=>{
    dispatch(getLicense())
    if(LicenseStateData.length){
      setAllData(LicenseStateData)
      setData(LicenseStateData.find((x)=>x._id ===_id))
    }
    else
     setAllData([])
  })()
},[LicenseStateData?.length])
  
  return (
    <>
    <div className="container-fluid">
    <div className="row">
      <h2 className='text-center bg-success text-light p-2'>Admin License Update Page</h2>
    <div className="col-md-3">
      <SideBar/>
    </div>
    <div className="col-md-9 license-create-form">
      <h4 className='text-center text-light bg-primary p-2'>UPDATE LICENSE <Link to="/admin/license"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      {/* <form onSubmit={postData}>
    <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputname" className="form-label">License Name*</label>
    <input type="text" name='name' className="form-control"  value={data?.name} onChange={getInputData} id="exampleInputname" aria-describedby="emailHelp"/>
    {show && errorMessage.name? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
  </div>
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputpic" className="form-label">License Pic*</label>
    <input type="file" name='pic' className="form-control" onChange={getInputData} id="exampleInputpic"/>
    {show && errorMessage.pic ? <p className='text-danger text-capitalize'>{errorMessage.pic}</p>:""}

  </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">About License Heading * </label>
    <input type="text" name='aboutLicense' className="form-control"  value={data?.aboutLicense} onChange={getInputData} id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.aboutLicense? <p className='text-danger text-capitalize'>{errorMessage.aboutLicense}</p>:""}
  
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputapplyLicenseName" className="form-label">What Is License Heading ?* </label>
    <input type="text" name='licenseHeading' className="form-control"  value={data?.licenseHeading} onChange={getInputData} id="exampleInputapplyLicenseName"/>
    <div  className="form-text">Enter License Name Here <i>Ex. FSSAI License</i></div>
    {show && errorMessage.licenseHeading? <p className='text-danger text-capitalize'>{errorMessage.licenseHeading}</p>:""}
  </div>
  <div className="col-md-6  mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> About License Details * </label>
    <input type="text" name='aboutLicenseDetails' className="form-control" onChange={getInputData} id="exampleInputgetLicenseName"/> 
    {show && errorMessage.aboutLicenseDetails? <p className='text-danger text-capitalize'>{errorMessage.aboutLicenseDetails}</p>:""}
  </div>
  </div>
 
  </div>
  <div className="row">
  <div className="col-md-6  mb-3">
    <label htmlFor="exampleInputapplyLicenseDetails" className="form-label">What Is License Heading Details* </label>
    <input type="text" name='licenseHeadingDetails' className="form-control"  value={data?.licenseHeadingDetails} onChange={getInputData} id="exampleInputlicenseHeadingDetails" aria-describedby="emailHelp"/>
    {show && errorMessage.licenseHeadingDetails? <p className='text-danger text-capitalize'>{errorMessage.licenseHeadingDetails}</p>:""}
  
  </div>

  <div className="col-md-6  mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> About License Details * </label>
    <input type="text" name='aboutLicenseDetails' className="form-control" onChange={getInputData} id="exampleInputgetLicenseName"/> 
    {show && errorMessage.aboutLicenseDetails? <p className='text-danger text-capitalize'>{errorMessage.aboutLicenseDetails}</p>:""}
    
  </div>

  </div>
  <div className="row">
  <div className="  mb-3">
    <label htmlFor="exampleInputapplyLicenseDetails" className="form-label">What Is License Required  Document* </label>
    <input type="text" name='requiredDocumentHeading' className="form-control"  value={data?.requiredDocumentHeading} onChange={getInputData} id="exampleInputapplyLicenseDetails" aria-describedby="emailHelp"/>
  </div>
  </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 1 ?* </label>
    <input type="text" name='document1' className="form-control"  value={data?.document1} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 2 ?* </label>
    <input type="text" name='document2' className="form-control"  value={data?.document2} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 3 ?* </label>
    <input type="text" name='document3' className="form-control"  value={data?.document3} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 4 ?* </label>
    <input type="text" name='document4' className="form-control"  value={data?.document4} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 5 ?* </label>
    <input type="text" name='document5' className="form-control"  value={data?.document5} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 6 ?* </label>
    <input type="text" name='document6' className="form-control"  value={data?.document6} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 7 ?* </label>
    <input type="text" name='document7' className="form-control"  value={data?.document7} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> Why Customer Required Heading 4 ?* </label>
    <input type="text" name='document8' className="form-control"  value={data?.document8} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className="col-md-12 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 9 ?* </label>
    <input type="text" name='document9' value={data?.document9} className="form-control" onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
  <button type="submit" className="btn btn-primary w-100">Update</button>
     </form>  */}
     <form onSubmit={postData}>
    <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputname" className="form-label">License Name*</label>
    <input type="text" name='name' className="form-control" value={data?.name} onChange={getInputData} id="exampleInputname" aria-describedby="emailHelp"/>
    {show && errorMessage.name? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
  </div>
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputpic" className="form-label">License Pic*</label>
    <input type="file" name='pic' className="form-control"  onChange={getInputData} id="exampleInputpic"/>
    {show && errorMessage.pic ? <p className='text-danger text-capitalize'>{errorMessage.pic}</p>:""}

  </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">About License Heading * </label>
    <input type="text" name='aboutLicense' className="form-control" value={data?.aboutLicense} onChange={getInputData} id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.aboutLicense? <p className='text-danger text-capitalize'>{errorMessage.aboutLicense}</p>:""}
  
  </div>
  <div className="col-md-6  mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> About License Details * </label>
    <input type="text" name='aboutLicenseDetails' className="form-control" value={data?.aboutLicenseDetails} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    {show && errorMessage.aboutLicenseDetails? <p className='text-danger text-capitalize'>{errorMessage.aboutLicenseDetails}</p>:""}
    
  </div>

 
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputapplyLicenseName" className="form-label">What Is License Heading ?* </label>
    <input type="text" name='licenseHeading' className="form-control" value={data?.licenseHeading} onChange={getInputData} id="exampleInputapplyLicenseName"/>
    <div  className="form-text">Enter License Name Here <i>Ex. FSSAI License</i></div>
    {show && errorMessage.licenseHeading? <p className='text-danger text-capitalize'>{errorMessage.licenseHeading}</p>:""}

  </div>
  <div className="col-md-6  mb-3">
    <label htmlFor="exampleInputapplyLicenseDetails" className="form-label">What Is License Heading Details* </label>
    <input type="text" name='licenseHeadingDetails' className="form-control" value={data?.licenseHeadingDetails} onChange={getInputData} id="exampleInputlicenseHeadingDetails" aria-describedby="emailHelp"/>
    {show && errorMessage.licenseHeadingDetails? <p className='text-danger text-capitalize'>{errorMessage.licenseHeadingDetails}</p>:""}
  
  </div>

  </div>
  <div className="row">
  <div className="  mb-3">
    <label htmlFor="exampleInputapplyLicenseDetails" className="form-label">What Is License Required Details / Document* </label>
    <input type="text" name='requiredDocumentHeading' className="form-control" value={data?.requiredDocumentHeading} onChange={getInputData} id="exampleInputapplyLicenseDetails" aria-describedby="emailHelp"/>
  </div>
  </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 1 * </label>
    <input type="text" name='document1' className="form-control" value={data?.document1} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 2 ?* </label>
    <input type="text" name='document2' className="form-control" value={data?.document2} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 3 ?* </label>
    <input type="text" name='document3' className="form-control" value={data?.document3} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 4 ?* </label>
    <input type="text" name='document4' className="form-control" value={data?.document4} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 5 ?* </label>
    <input type="text" name='document5' className="form-control" value={data?.document5} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 6 ?* </label>
    <input type="text" name='document6' className="form-control" value={data?.document6} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 <div className="row">
 <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 7 ?* </label>
    <input type="text" name='document7' className="form-control" value={data?.document7} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
  <div className=" col-md-6 mb-3"  >
    <label htmlFor="exampleInputgetLicenseName" className="form-label"> What Is License Required Ans Line 8 ?* </label>
    <input type="text" name='document8' className="form-control" value={data?.document8} onChange={getInputData} id="exampleInputgetLicenseName"/> 
    <div  className="form-text">Enter Get License Name Here <i>Ex. FSSAI License</i></div> 
  </div>
 </div>
 

  <button type="submit" className="btn btn-primary w-100">Update</button>
     </form>
    </div>
    </div>
  </div>
    </>
  )
}
