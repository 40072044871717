import React, { useEffect, useState } from 'react'
import SideBar from '../partials/SideBar'
import {   Link, useNavigate } from 'react-router-dom'
import FormValidation from '../Validation/FormValidation'



export default function AdminProfileUpdate() {
let [show,setShow]=useState(false)
  let navigate = useNavigate()
  let [data, setData]= useState({
    username:"",
    name:"",
    email:"",
    phone:"",

  
  })
  let [errorMessage,setErrorMessage]=useState({
    username:"",
    name:"",
    email:"",
    phone:"",
 
  })

 
  // getInputData
  function getInputData(e){
    let {name,value} = e.target
    if(name==="username" || name ==="phone" || name === "email" || name==="name" || name === "password" ){
      setErrorMessage((old)=>{
        return{
          ...old,
          [name]:FormValidation(e)
        }
      })
    }
    
    setData((old)=>{
      return{
        ...old,
        [name]:value
      }
    })
  }

// post Update data
 async function postData(e) {
  e.preventDefault()
  let error = Object.values(errorMessage).find(x=> x!== "")

if(error){
  setShow(true)
}
 else{
    let response = await fetch("/api/admin/"+data._id,{
        method:"PUT",
        headers:{
          "content-type":"application/json",
          "authorization":localStorage.getItem("token")
        },
        body:JSON.stringify({...data})
      })
     response = await response.json()
      if(response.result==="Done"){
        if(data.role==="Admin")
        navigate("/admin")
       }
        else 
      navigate("/")
  }

}



useEffect(()=>{
  (async()=>{
    let response = await fetch("https://pgc.onrender.com/api/admin/" + localStorage.getItem("userid") ,{
      method:"GET",
      headers:{
        "content-type":"application/json",
        "authorization" : localStorage.getItem("token")
      }
    })
    response = await response.json()
    if(response.result==="Done"){
      setData((old)=>{
        return {
          ...old,
           ...response.data
        }
      })
    }
    else
      navigate("/admin")
  })()
},[data?.length])


  return (
  <>
  <div className="container-fluid">
    <div className="row">
      <h4 className='text-center bg-success text-light py-2'>Admin Profile Update </h4>
      <div className="col-md-3">
        <SideBar/>
      </div>
      
      <div className="col-md-9 license-create-form">
      <h4 className='text-center text-light bg-primary p-2'> Profile Update<Link to="/admin"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      <form onSubmit={postData}>
    <div className="row">
 
  <div className="  mb-3">
    <label htmlFor="exampleInputName" className="form-label">Name*</label>
    <input type="name" name='name' onChange={getInputData} value={data.name} className="form-control" id="exampleInputName"/>
    {show && errorMessage.name? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
    </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputUsername" className="form-label">UserName*</label>
    <input type="text" name='username' onChange={getInputData} value={data.username} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
  {show && errorMessage.username ? <p className='text-danger text-capitalize'>{errorMessage.username}</p>:""}
  </div>
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputEmail" className="form-label">Email Address* </label>
    <input type="email" name='email' onChange={getInputData} value={data.email} className="form-control" id="exampleInputEmail"/>
    {show && errorMessage.email ? <p className='text-danger text-capitalize'>{errorMessage.email}</p>:""}
    </div>
  </div>
  <div className="row">
  <div className=" mb-3">
    <label htmlFor="exampleInputPhone" className="form-label">Phone* </label>
    <input type="number" name='phone' onChange={getInputData} value={data.phone} className="form-control" id="exampleInputPhone" aria-describedby="emailHelp"/>
    {show && errorMessage.phone ? <p className='text-danger text-capitalize'>{errorMessage.phone}</p>:""}
    </div>
  
  </div>
  <button type="submit" className="btn btn-primary w-100">Update</button>
</form> 
    </div>
      </div>
    </div>
    
  
  </>
  )
}
