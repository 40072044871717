// Testimonials 
export const CREATE_TESTIMONIAL = "CREATE_TESTIMONIAL";
export const CREATE_TESTIMONIAL_RED = "CREATE_TESTIMONIAL_RED";
export const GET_TESTIMONIAL = "GET_TESTIMONIAL";
export const GET_TESTIMONIAL_RED = "GET_TESTIMONIAL_RED";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
export const DELETE_TESTIMONIAL_RED = "DELETE_TESTIMONIAL_RED";

// License 
export const CREATE_LICENSE = "CREATE_LICENSE";
export const CREATE_LICENSE_RED = "CREATE_LICENSE_RED";
export const GET_LICENSE = "GET_LICENSE";
export const GET_LICENSE_RED = "GET_LICENSE_RED";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const UPDATE_LICENSE_RED = "UPDATE_LICENSE_RED";
export const DELETE_LICENSE = "DELETE_LICENSE";
export const DELETE_LICENSE_RED = "DELETE_LICENSE_RED";

// Client 
export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_RED = "CREATE_CLIENT_RED";
export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENT_RED = "GET_CLIENT_RED";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_RED = "UPDATE_CLIENT_RED";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_RED = "DELETE_CLIENT_RED";

// Admin 
export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_RED = "CREATE_ADMIN_RED";
export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_RED = "GET_ADMIN_RED";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_RED = "UPDATE_ADMIN_RED";

// Query
export const CREATE_QUERY = "CREATE_QUERY";
export const CREATE_QUERY_RED = "CREATE_QUERY_RED";
export const GET_QUERY = "GET_QUERY";
export const GET_QUERY_RED = "GET_QUERY_RED";
export const DELETE_QUERY = "DELETE_QUERY";
export const DELETE_QUERY_RED = "DELETE_QUERY_RED";

// website Query 
export const CREATE_WEBQUERY = "CREATE_WEBQUERY";
export const CREATE_WEBQUERY_RED = "CREATE_WEBQUERY_RED";
export const GET_WEBQUERY = "GET_WEBQUERY";
export const GET_WEBQUERY_RED = "GET_WEBQUERY_RED";
export const DELETE_WEBQUERY = "DELETE_WEBQUERY";
export const DELETE_WEBQUERY_RED = "DELETE_WEBQUERY_RED";