import React, { useEffect, useState } from 'react'
import SideBar from '../../partials/SideBar'
import {Link, useNavigate, useParams } from 'react-router-dom'
import imageValidation from '../../Validation/imageValidation'
import FormValidation from '../../Validation/FormValidation'

import {getTestimonial,createTestimonial} from "../../../Store/ActionCreaters/TestimonialsActionCreaters"
import { useDispatch, useSelector } from 'react-redux'

export default function AdminCreateTestimonial() {
  var {_id} = useParams()

  let [show, setShow]=useState(false)
 let [data,setData]= useState({
   name:"",
   pic:"",
   profile:"",
   message:""
 })

 let [errorMessage,setErrorMessage]= useState({
  name:"Testimonial Name Field Is Required* ",
  pic:"Pic Field Is Required* ",
  profile:"Profile Field Is Required* ",
  message:"Message Field Is Required* ",
 })
 let navigate =useNavigate()
 let  dispatch = useDispatch()

 let TestimonialStateData = useSelector((state)=>state.TestimonialStateData)
//  get inputData
function getInputData(e){
  let name= e.target.name;
  let value =e.target.files? e.target.files[0]:e.target.value;
 if(name ==="name" || name==="pic" || name==="profile" || name === "message"){
  setErrorMessage((old)=>{
    return{
        ...old,
        [name]:e.target.files ?imageValidation(e):FormValidation(e)
    }
  })
 }
 setData((old)=>{
  return{
    ...old,
    [name]:value
  }
 })
}
 async function postData(e){
  e.preventDefault() 
  let error = Object.values(errorMessage).find((x)=>x?.length > 0)
  if(error){
    setShow(true)
  }
  else{   
    let item = TestimonialStateData.find((x)=>x.name.toLowerCase() === data.name.toLowerCase() && x._id === _id)
    if(item){
      setShow(true)
      setErrorMessage((old)=>{
        return{
          ...old,
          "name" :"Name Field Is  Already Exist"
        }
      })
    }
    else{

      let formData = new FormData()
      formData.append("name", data.name)
      formData.append("pic", data.pic)
      formData.append("profile", data.profile)
      formData.append("message", data.message)
      dispatch(createTestimonial( formData))
      navigate("/admin/testimonial")
    }
  }
 } 

 useEffect(()=>{
  (()=>{
    dispatch(getTestimonial())
  })()
 },[TestimonialStateData?.length])

  return (
  <>
  <div className="container-fluid">
    <div className="row">
      <h2 className='text-center bg-success text-light p-2'>Admin Testimonial Create Page</h2>
    <div className="col-md-3">
      <SideBar/>
    </div>
    <div className="col-md-9 license-create-form">
      <h4 className='text-center text-light bg-primary p-2'>Create Testimonial <Link to="/admin/testimonial"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      <form onSubmit={postData}>
    <div className="row">
  <div className=" mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label"> Name*</label>
    <input type="text" name='name' onChange={getInputData} placeholder='Enter the Name' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.name ?<p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
  </div>
  </div>
  <div className="row">
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Pic* </label>
    <input type="file" name='pic' onChange={getInputData} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.pic ?<p className='text-danger text-capitalize'>{errorMessage.pic}</p>:""}
  </div>
  <div className=" col-md-6 mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">Profile*</label>
    <input type="text" name='profile'  onChange={getInputData} placeholder='Enter the Profile' className="form-control" id="exampleInputPassword1"/>
    {show && errorMessage.profile ?<p className='text-danger text-capitalize'>{errorMessage.profile}</p>:""}
  </div>
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputMessage" className="form-label">Message* </label>
    <input type="text" name='message'  onChange={getInputData} placeholder='Message .....' className="form-control" id="exampleInputPassword1"/>
    {show && errorMessage.message ?<p className='text-danger text-capitalize'>{errorMessage.message}</p>:""}
  </div>

  <button type="submit" className="btn btn-primary w-100">Create</button>
</form> 
    </div>
    </div>
  </div>
  </>
  )
}
