import { CREATE_CLIENT, DELETE_CLIENT, GET_CLIENT, UPDATE_CLIENT } from "../Constant";

export function createClient(data){
    return{
        type:CREATE_CLIENT,
        payload: data
    }
}

export function getClient(){
    return{
        type:GET_CLIENT
    }
}

export function updateClient(data){
    return{
        type:UPDATE_CLIENT,
        payload: data
    }
}

export function deleteClient(data){
    return{
        type:DELETE_CLIENT,
        payload: data
    }
}