import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import data from "../../Data/data"
import {getLicense} from "../../Store/ActionCreaters/LicenseActionCreaters"
import { useDispatch, useSelector } from 'react-redux'
export default function OurServices() {
  let [data,setData]=useState([])
  let dispatch = useDispatch()
  let LicenseStateData = useSelector((x)=>x.LicenseStateData)
 
    // Scroll to up
    const goToTop =()=>{
      setTimeout(() => {
        refreshPage()
      }, 200)
      window.scrollTo({top:0,left:0 ,behavior:"smooth"})
      
  }
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0); 
  }

  function getAPIData(){
       dispatch(getLicense())
       if(LicenseStateData.length){
        setData(LicenseStateData)
       }
       else setData([])
  }

  // useEffect(()=>{
  //     dispatch(getLicense)()
  //     if(LicenseStateData.length){
  //       setData({...LicenseStateData})
  //     }
  //     else
  //     setData([])
  // },[LicenseStateData.length])
  useEffect(()=>{
    getAPIData()
  },[LicenseStateData.length])
  return (


<>


{/* <div className="our-services my-2">
  <h2 className='text-center fs-2'>Our Services</h2>
  <div className="our-ser-container">
  
  <div className="items">
    <Link to="/fssai-license">Fssai License</Link> |
  </div>
  <div className="items">
    <Link to="/dl-police-eating-house">DL Police Eating House </Link> |
  </div>
  <div className="items">
    <Link to="/dl-police-guest-house">DL Police Guest House</Link>  |
  </div>
  <div className="items">
    <Link to="/delhi-police-pcc">Delhi Police (PCC)</Link> |
  </div>
  <div className="items">
    <Link to="/mcd-license">MCD All License Apply</Link> |
  </div>
  <div className="items">
    <Link to="/delhi-police-report">Delhi Police (Report)</Link> |
  </div>
  <div className="items">
    <Link to="/mcd-trade-license">MCD Trade License</Link> |
  </div>
  <div className="items">
    <Link to="/mcd-prop-tex-filling">MCD Prop.Tax Filling</Link> |
  </div>
  <div className="items">
    <Link to="/msme-udyam">Online MSME(UDYAM)</Link> |
  </div>
  <div className="items">
    <Link to="/udyog-aadhar">Udyog AAdhar Online</Link> |
  </div>
  <div className="items">
    <Link to="/rcmc-license">RCMC Online</Link> |
  </div>
  <div className="items">
    <Link to="/import-export-license">Import & Export(IEC)</Link> |
  </div>
  <div className="items">
    <Link to="/gst-license">G.S.T Registration</Link> |
  </div>
  <div className="items">
    <Link to="/gst-filling">G.S.T Filling</Link> |
  </div>
  <div className="items">
    <Link to="/trade-mark-license">Trade-Mark Registration</Link> | </div>
  <div className="items">
    <Link to="/patent-license">Patent Registration</Link> |
  </div>
  <div className="items">
    <Link to="/industrial-license">Industrial License</Link> |
  </div>
  <div className="items">
    <Link to="/gem-license">GeM License </Link> |
  </div>
  <div className="items">
    <Link to="/business-registration">Business Registration</Link> |
  </div>
  <div className="items">
    <Link to="/business-registration">DDA Property Apply</Link>
  </div>

  

</div>
</div> */}



 <div className="our-services my-2">
   <h2 className='text-center fs-2'>Our Services</h2>
   <div className="our-ser-container">
  
   
   {
    LicenseStateData.map((x,y)=>{
      return(
        <div className="items" key={y}>
     <Link to={`/services/${x._id}`} onClick={goToTop}>{x.name} </Link> |
   </div>
      )
    })
   }
  

 </div>
 </div> *




</>
)
}
