import PasswordValidator from "password-validator";

var schema = new PasswordValidator();

// Add properties to it
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase(1) // Must have uppercase letters
  .has()
  .lowercase(1) // Must have lowercase letters
  .has()
  .digits(1) // Must have at least 2 digits
  .has()
  .not()
  .spaces() // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123", "admin@123"]); // Blacklist these values

export default function FormValidation(e) {
  var { name, value } = e.target;
  switch (name) {
    case "name":
    case "username":
      if (!value || value.length === 0)
         return name + " is Required";
      else if (value.length < 3 || value.lenght > 10)
        return name + " Field length Must Be 3-50";
      else return "";
    
    case "email":
      if (!value || value.length === 0) return name + " is Required";
      else if (value.length < 13 || value.lenght > 50)
        return name + " Field length Must Be 13-50";
      else return "";
    
      case "otp":
        if (!value || value.length === 0) return name + " is Required";
        else if (value.length < 4 || value.lenght >10)
          return name + " Field length Must Be 13-50";
        else return "";

    case "aboutLicense":
    case  "aboutLicenseDetails":
    case "licenseHeading":
      if(!value ||value.length === 0)
        return name + " Field Is Required "
      else if(value.length<10 || value.length>9000)
        return `${name} Field length Must Be 10 - 9000 `
      else return ""

      case "licenseHeadingDetails":
    
      if(!value || value.length === 0)
        return  name + "Field Is Reuired"
      else if (value.length< 3 || value.length >9000)
        return name + "Field Is Length Must Be 10-9000"
      else return  ""

    case "password":
      if(!value || value.length === 0)
        return name + "Field Is Required"
      else if(schema.validate(value))
        return ""
      else  return "Password must containat lest 1 upper case character, 1 lower case character , 1 digit  must contain 8-100 ";


    case "phone":
      if (!value || value.length === 0) 
        return name + " is Required";
      else if (value.length !== 10) 
        return "Invailid Phone Number";
      else if (
        value.startsWith("6") ||
        value.startsWith("7") ||
        value.startsWith("8") ||
        value.startsWith("9")
      )
        return "";
      else return "Invalid Phone Number  "
    case "address":
    case "message":
      if (!value || value.length === 0) 
        return name + " is Required";
      else if (value.length <3) 
        return name + " Please enter  minimum 3 letter";
      else return "";
    default:
      return "";
  }
}