import React, { useEffect } from 'react'
import { getTestimonial } from '../../Store/ActionCreaters/TestimonialsActionCreaters'
import { useDispatch, useSelector } from 'react-redux'

export default function Testimonials() {
 
  let dispatch = useDispatch()
  let TestimonialStateData = useSelector((state)=>state.TestimonialStateData)

 function getAPIData(){
	// let response = await fetch ("http://localhost:8000/testimonial",{
	// 	method:"GET",
	// 	headers:{
	// 		"content-type":"application/json"
	// 	}
	// })
	// response = await  response.json()
  dispatch(getTestimonial())
}
useEffect(()=>{
	getAPIData()
},[TestimonialStateData.length])
return (
    <>
 


 <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    {/* <!-- Testimonial 1 --> */}
	{
	TestimonialStateData?.slice(0,6).map((item,index)=>{
			return(
				<div className="carousel-item active " key={index}> 
				<div className="card testimonial-card" >
	  <img src={`https://pgc.onrender.com/${item.pic}`} alt="No img" height={"60px"} width={"60px"} className="mx-auto d-block rounded-circle"/>
      <div className="testimonial-item">
        <p className="testimonial-text">{item?.message}</p>
        <p className="testimonial-author">{item?.name}</p>
        <p className="testimonial-role">{item?.profile}</p>
      </div>
	  </div>
    </div>
			)
		})
	}
  </div>
  

  <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> 
    </>
  )
}
