import { all } from "redux-saga/effects";

// import testimonialSagas from  './Services/TestimonialSags';
import licenseSagas from "./Services/LicenseSags"
import testimonialSagas from "./Services/TestimonialSags";
import clientSagas from "./Services/ClientSags";
import adminSagas from "./Services/AdminSags";
import querySagas from "./Services/QuerySags";
import webQuerySagas from "./Services/WebQuerySags";


export default function* RootSagas(){
    yield all([
       licenseSagas(),
        testimonialSagas(),
        clientSagas(),
        adminSagas(),
        querySagas(),
        webQuerySagas()
         
    ])
} 