import {  GET_ADMIN_RED, UPDATE_ADMIN_RED, CREATE_ADMIN_RED } from "../Constant";

export default function AdminReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_ADMIN_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_ADMIN_RED:
            return action.payload

        case UPDATE_ADMIN_RED:
            index=state.findIndex((x)=>x.id === action.payload._id)
            state[index].name=action.payload.name
            // state[index].pic=action.payload.pic
            state[index].username=action.payload.username
            state[index].phone=action.payload.phone
            state[index].email=action.payload.email
            state[index].password=action.payload.password
            return state
        
        // case DELETE_CLIENT_RED:
        //     return state.filter((x)=> x.id !== action.payload.id)

        
        default :
            return state
    }
}