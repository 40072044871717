import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import AdminHome from '../AdminHome'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid} from "@mui/x-data-grid";

import {getTestimonial,deleteTestimonial} from "../../../Store/ActionCreaters/TestimonialsActionCreaters"

export default function AdminTestimonial() {
let [data,setData]=useState([])
const columns = [
    { field: "_id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "profile", headerName: "Address", width:350 },
    { field: "message", headerName: "Message", width:350 },
    { field: "pic", headerName: "Pic", width: 200 , renderCell:({row})=>
        <a href={`https://pgc.onrender.com/${row.pic}`} target='_blank' rel="noreferrer" >
        <img src={`https://pgc.onrender.com/${row.pic}`} height={50} width={50} alt=" no-image" /> </a>
        },

    { field: "delete", headerName: "Delete", width: 100 , renderCell:({row})=> <button className="btn btn-danger" onClick={()=>deleteData(row._id)}><i className="bi bi-trash-fill text-light fs-5"></i></button>}
  ];
 let dispatch =useDispatch()
let TestimonialStateData = useSelector((state)=>state.TestimonialStateData)

 function deleteData(_id) {
        if (window.confirm("You Are Sure to Delete This Item : " )) {
            dispatch(deleteTestimonial({_id:_id}))
            getAPIData()
        }
}


 function getAPIData(){
    dispatch(getTestimonial())
    if(TestimonialStateData?.length)
     setData(TestimonialStateData)
    else
    setData([])
 } 

useEffect(()=>{
   getAPIData()
},[TestimonialStateData?.length])

  return (
        <>
<div className="container-fluid">
    <div className="row">
    <h2 className='text-center bg-success w-100 py-2 text-light'>Admin Testimonial Page</h2>
<AdminHome/>   
    </div>
    <div className="row">
        <div className="col-12 table-responsive">
        <h2 className='text-center bg-success text-light p-2 my-2'>Testimonial Table <Link to="/admin/testimonial/create"> 
        <i className="bi bi-plus fs-2 text-center text-light  float-end" ></i></Link></h2>
     {
      TestimonialStateData?.length ?
      <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row._id} 
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10,20,50,100]}
        checkboxSelection={false}
      />
    </div>
      : <h4 className='text-center'> Sorry ! <i className="bi bi-emoji-frown"></i> Testimonial Table Record Not Found</h4>
     }
        </div>
        </div>
</div>
        </>
  )
}
