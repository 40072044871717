import React, { useEffect, useState } from 'react'
import SideBar from '../../partials/SideBar'
import FormValidation from '../../Validation/FormValidation'
import { Link, useNavigate, useParams } from 'react-router-dom'
import imageValidation from '../../Validation/imageValidation'

import { getClient,updateClient } from '../../../Store/ActionCreaters/ClientActionCreaters'
import { useDispatch, useSelector } from 'react-redux'

export default function AdminUpdateClient() {
  let [allData , setAllData]= useState([])
  let [client,setClient]= useState()
  let navigate =useNavigate()
  let {_id}= useParams()
  let [data,setData]=useState({
    name:"",
    pic:"",
    address:"",
    
  })
  let [errorMessage,setErrorMessage] =useState({
    name:"",
    address:"",
  })
  let [show,setShow]=useState(false)
  let dispatch =useDispatch()
  let ClientStateData = useSelector((state)=>state.ClientStateData)

// getInputdata
function getInputData(e){
  let name = e.target.name;
  let value  = e.target.files ?e.target.files[0]:e.target.value
    if(name==="name" || name==="address"){
      setErrorMessage((old)=>{
        return{
          ...old,
          [name]:e.target.files ? imageValidation(e): FormValidation(e)
        }
      })
    
  }
  setData((old)=>{
   return{
    ...old,
     [name]:value
 }})
 }

//  post data
function postData(e){
  e.preventDefault()
  let error= Object.values(errorMessage).find((x)=>x.length >0)
  if(error){
    setShow(true)
  }
  else{
    let item = allData.find((x)=>x.name?.toLowerCase() === data.name.toLowerCase() && x._id !== _id)
    if(item){
      setShow(true)
      setErrorMessage((old)=>{
        return{
          ...old,
          name:"Sorry !! Client Name Is Already Exist !!"
        }
      })
    }
    else{
    let formData = new FormData()
    formData.append("_id", data._id)
    formData.append("name", data.name)
    formData.append("pic", data.pic)
    formData.append("address", data.address)

    dispatch(updateClient(formData))
      navigate("/admin/client")
    }
  }
 }


useEffect(()=>{
  (()=>{
   dispatch(getClient())
   if(ClientStateData.length){
    setAllData(ClientStateData)
    setData( ClientStateData.find((x)=>x._id ===_id))
   }
   else
    setData([])
  })()
},[ClientStateData.length])
  
  return (
    <>
    <div className="container-fluid">
    <div className="row">
      <h2 className='text-center bg-success text-light p-2'>Admin Client Create Page</h2>
    <div className="col-md-3">
      <SideBar/>
    </div>
    <div className="col-md-9 license-create-form">
      <h4 className='text-center text-light bg-primary p-2'>UPDATE Client <Link to="/admin/client"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      <form onSubmit={postData}>
    <div className="row">
  <div className=" mb-3">
    <label htmlFor="exampleInputname" className="form-label">  Name*</label>
    <input type="text" name='name' value={data.name} className="form-control" onChange={getInputData} id="exampleInputname" aria-describedby="emailHelp"/>
    {show && errorMessage.name ? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
  </div>
  </div>
  <div className='row'>
  <div className="  mb-3">
    <label htmlFor="exampleInputpic" className="form-label">License Pic*</label>
    <input type="file" name='pic'  className="form-control" onChange={getInputData} id="exampleInputpic"/>
  </div>
  </div>
  <div className="row">
  <div className="  mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Address* </label>
    <input type="text" name='address' value={data.address} className="form-control" onChange={getInputData} id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.address ? <p className='text-danger text-capitalize'>{errorMessage.address}</p>:""}
  </div>
  
  </div>

  <button type="submit" className="btn btn-primary w-100">Update</button>
</form> 
    </div>
    </div>
  </div>
    </>
  )
}
