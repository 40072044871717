import React from 'react'
import { Link } from 'react-router-dom'

export default function Confirm() {
  return (
    <>
      <div className='text-center my-2'>
        <h1 className='text-success'>!!!!! Thank You !!!!! </h1>
        <h3>Your Password Has Been Reset SuccessFully </h3>
        <Link to="/login" className='btn bg-warning text-dark'> Back To Login Page</Link>

    </div>
    </>
  )
}
