
import { useTypewriter, Cursor } from 'react-simple-typewriter';

function Slider() {
  const [ text1 ] = useTypewriter({
    words:['FSSAI License', 'DPCC/ Fire License ', 'Excise License L-17, P-10','Delhi Police(Eating House) ','Delhi Police(Guest House /Hotel) '  ,'MCD All License', 'Web Development','MSME(udyam)',
      'Import & Export Reg.','RCMC Registration','Web Designing'],
    loop:{},
    typeSpeed: 100,
    deleteSpeed: 40,
  })


  return (
   <>
    <div className="container-fluid">
      <div className="row " style={{backgroundColor:"#EEEEEE"}}>
        <div className="col-md-8">
          <h1 className='slider' style={{ fontFamily:'sans-serif', fontWeight:"400"}}>
          Perfect Global Consultancy is Best 
          Known for 
          <span className='slider-span' style={{color:' #F19A17', marginLeft:"12px"}}>
            {text1}
          </span>
          <span>
          <Cursor/>
          </span>
          </h1>
        </div>
        <div className="col-md-4">

        {/* <Carousel>
      <Carousel.Item>
        <img src='./img/k.jpg'  className='slider-img' alt='imgage' />
        <Carousel.Caption >
          <h3 className='slider-heading' >Mr. Anup Khanna </h3> <br />
      
          <p className='slider-para'>Founder & CEO Perfect Global Consultancy </p>
        </Carousel.Caption>
      </Carousel.Item>

     <Carousel.Item>
        <img src='./img/k.jpg'  className='slider-img' alt='imgage' />
        <Carousel.Caption >
          <h3 className='slider-heading' >Mr.Anup Khanna </h3> <br />
        
      
          <p className='slider-para'>Founder & CEO Perfect Global Consultancy </p>
        </Carousel.Caption>
      </Carousel.Item>

       <Carousel.Item>
        <img src='./img/img2.jpg'  className='slider-img' alt='imgage' />
        <Carousel.Caption >
          <h3 className='slider-heading' >Mr.Anup Khanna </h3> <br />
      
          <p className='slider-para'>Founder & CEO Perfect Global Consultancy </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}

          <div className="carousel-slider ">
          <img src="./img/rm1.png" className='slider-img' alt="slider-img"  />
          <h3 className='slider-heading text-center' >Mr. Anup Khanna </h3> <br />
          <p className='slider-para  text-center'>Founder of <span style={{color:"#F19A17", fontWeight:"400"}}>Perfect Global Consultancy </span></p>
          </div>
        </div>

      </div>
    </div>
   </>
  );
}

export default Slider;