import React, { useState } from 'react'
import {  Link, useNavigate } from 'react-router-dom'
import FormValidation from './Validation/FormValidation'


export default function ForgetPassword2() {
   let [pstatus,setPstatus]= useState(false)
  let[data,setData]=useState({
    confirmPassword:"",
    password:""
  })

  let [errorMessage,setErrorMessage]=useState({
    password:""
  })
  let [show,setShow]=useState(false)
  let navigate =useNavigate()
 
 function getInputData(e){
     var {name, value}=e.target
     setErrorMessage("")
     if(name !== "confirmPassword"){
     setErrorMessage((old)=>{
      return{
          ...old,
          [name]:FormValidation(e)
      }
  })
     }
     setData((old)=>{
      return{
        ...old,
        [name]:value
      }
     })
 }

 
 async function postData(e){
  e.preventDefault()
  let error = Object.values(errorMessage).find((x)=>x !== "")
  if(error){
    setShow(true)
  }
  else if(data.password !== data.confirmPassword){
    setShow(true)
    setErrorMessage((old)=>{
      return{
        ...old,
        "password":"Password or Confirm Password Not Match"
      }
    })
  }
  else{
    let response = await fetch("https://pgc.onrender.com/api/admin/forget-password-3",{
      method:"POST",
      headers:{
       "content-type":"application/json",
       "authorization":localStorage.getItem("token")
      },
      body:JSON.stringify({username:localStorage.getItem("password-reset-admin"),password:data.password})
   })
   response = await response.json()
   if(response.result === "Done"){
    localStorage.removeItem("password-reset-admin")
       navigate("/confirm")
      }
   else
    setShow(true)
   setErrorMessage(response.reason)
   }
  }
 
  return (
    <>
  <div className="container-fluid my-3">
    <div className="row">
        <div className="col-md-6 m-auto">
        <h5 className='bg-success rounded-3 p-2 text-light text-center'>
        <Link to="/forget-password-1"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link>
          
          Forget Your Password</h5>
        <form onSubmit={postData}>
           <div className="row">
            <div className="mb-3">
                <label className='mx-3'>Password :</label>
                <input type="text" name="password" onChange={getInputData} placeholder='Enter your Password' className='form-control border-2'/>
                {show && errorMessage ? <p className='text-danger text-capitalize'>{errorMessage.password}</p>:""}
            </div>
            </div>
            <div className="row">
            <div className="mb-3">
                <div>
                <label className='mx-3'>Confirm Password :</label>
                <input type= {`${pstatus? "text":"password"}`}  name="confirmPassword" onChange={getInputData} placeholder='Enter Your Confirm Password' className='form-control border-2'/>
                <span className='password-icon'  onClick={()=>setPstatus(!pstatus)}>{pstatus?<i className="bi bi-eye-fill float-end password-icon "></i>:<i className="bi bi-eye-slash-fill  float-end password-icon"></i>}
                {show && errorMessage ? <p className='text-danger text-capitalize fs-6'>{errorMessage.password}</p>:""}
       
                </span>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3" >
             <button type="submit"  className='btn btn-success login-bg text-light text-center p-2 w-100'>Reset Password</button>
      
            </div>
            </div>

        </form>
        </div>
    </div>
  </div>
    </>
  )
}
