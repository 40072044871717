import { put,takeEvery } from "redux-saga/effects";
import { CREATE_WEBQUERY, CREATE_WEBQUERY_RED, DELETE_WEBQUERY, DELETE_WEBQUERY_RED, GET_WEBQUERY, GET_WEBQUERY_RED } from "../../Constant";

import { createRecord, deleteRecord, getRecord,  } from './Services'


function* createSaga(action){
    let response= yield createRecord("webquery",action.payload)
    yield put({type:CREATE_WEBQUERY_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("webquery")
    yield put({type:GET_WEBQUERY_RED, payload:response.data})
}

// function* updateSaga(action){
//      yield updateRecord("webquery",action.payload)
//     yield put({type:UPDATE_WEBQUERY_RED , payload:action.payload})
// }

function* deleteSaga(action){
    yield deleteRecord("webquery",action.payload)
    yield put({type:DELETE_WEBQUERY_RED, payload:action.payload})
}

export default function* webQuerySagas(){
    yield takeEvery (CREATE_WEBQUERY, createSaga)
    yield takeEvery(GET_WEBQUERY, getSaga)
    // yield takeEvery(UPDATE_WEBQUERY,updateSaga)
    yield takeEvery(DELETE_WEBQUERY,deleteSaga)
}