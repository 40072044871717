import { put,takeEvery } from "redux-saga/effects";
import { GET_ADMIN, GET_ADMIN_RED, UPDATE_ADMIN, UPDATE_ADMIN_RED, CREATE_ADMIN, CREATE_ADMIN_RED } from "../../Constant";

import { createRecord,  getRecord, updateRecord } from './Services'



function* createSaga(action){
    let response= yield createRecord("admin",action.payload)
    yield put({type:CREATE_ADMIN_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("admin")
    yield put({type:GET_ADMIN_RED, payload:response.data})
}

function* updateSaga(action){
//     yield updateRecord("admin",action.payload)
//     yield put({type:UPDATE_ADMIN_RED , payload:action.payload})
    let response=  yield updateRecord("admin",action.payload)
    yield put({type:UPDATE_ADMIN_RED , payload:response.data})
}

// function* deleteSaga(action){
//     yield deleteRecord("client",action.payload)
//     yield put({type:DELETE_ADMIN_RED, payload:action.payload})
// }

export default function* adminSagas(){
    yield takeEvery (CREATE_ADMIN, createSaga)
    yield takeEvery(GET_ADMIN, getSaga)
    yield takeEvery(UPDATE_ADMIN,updateSaga)
    // yield takeEvery(DELETE_CLIENT,deleteSaga)
}