import React, { useEffect, useState } from 'react'
import SideBar from '../../partials/SideBar'
import { Link, useNavigate } from 'react-router-dom'
import FormValidation from '../../Validation/FormValidation'
import imageValidation from "../../Validation/imageValidation"

import { createClient, getClient } from '../../../Store/ActionCreaters/ClientActionCreaters'
import { useDispatch, useSelector } from 'react-redux'

export default function AdminCreateClient() {

  let [data,setData]=useState({
      name:"",
      pic:"",
      address:"",
  })

  let [errorMessage,setErrorMessage] =useState({
   name:"License Name Field Is Required",
   pic:"Pic File Is Required",
   address:"Address File Is Required",
  })
  let [show,setShow]= useState(false)
  let navigate = useNavigate()
  let dispatch = useDispatch()

  let ClientStateData = useSelector((state)=>state.ClientStateData)


// getInputData Found
function getInputData(e){
  let value  = e.target.files ? e.target.files[0]:e.target.value
  let name=e.target.name;
  if(name==="name" || name ==="pic" || name ==="address"){
    setErrorMessage((old)=>{
      return{
        ...old,
        [name]:e.target.files ? imageValidation(e): FormValidation(e)
      }
  })
  }
  setData((old)=>{
    return{
      ...old,
      [name]:value
    }
  })
}

// post Data
 function postData(e) {
  e.preventDefault()
  let error = Object.values(errorMessage).find((x)=> x?.length >0)
if(error){
  setShow(true)
}
else{
  let item = ClientStateData.find((x)=>x.name ?.toLowerCase() === data.name.toLowerCase() )

  if(item){
    setShow(true)
    setErrorMessage((old)=>{
      return{
        ...old,
        name:"Client Name Is Already Exists"
      }
    })
  }  
  else{
    let formData = new FormData()
    formData.append("name",data.name)
    formData.append("pic",data.pic)
    formData.append("address",data.address)

    dispatch(createClient(formData))
        navigate("/admin/client")
  }
}

}

useEffect(()=>{
  (()=>{
 dispatch(getClient())
  })()
},[ClientStateData.length])

  return (
  <>
  <div className="container-fluid">
    <div className="row">
      <h2 className='text-center bg-success text-light p-2'>Admin Client Create Page</h2>
    <div className="col-md-3">
      <SideBar/>
    </div>
    <div className="col-md-9 license-create-form">
      <h4 className='text-center text-light bg-primary p-2'>Create Client <Link to="/admin/client"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link></h4>
      <form onSubmit={postData}>
    <div className="row">
  <div className=" mb-3">
    <label htmlFor="exampleInputname" className="form-label">  Name*</label>
    <input type="text" name='name' className="form-control" onChange={getInputData} id="exampleInputname" aria-describedby="emailHelp"/>
    {show && errorMessage.name ? <p className='text-danger text-capitalize'>{errorMessage.name}</p>:""}
  </div>
  </div>
  <div className='row'>
  <div className="  mb-3">
    <label htmlFor="exampleInputpic" className="form-label">License Pic*</label>
    <input type="file" name='pic' className="form-control" onChange={getInputData} id="exampleInputpic"/>
    {show && errorMessage.pic ? <p className='text-danger text-capitalize'>{errorMessage.pic}</p>:""}

  </div>
  </div>
  <div className="row">
  <div className="  mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Address* </label>
    <input type="text" name='address' className="form-control" onChange={getInputData} id="exampleInputEmail1" aria-describedby="emailHelp"/>
    {show && errorMessage.address ? <p className='text-danger text-capitalize'>{errorMessage.address}</p>:""}
  </div>
  
  </div>

  <button type="submit" className="btn btn-primary w-100">Create</button>
</form> 
    </div>
    </div>
  </div>
  </>
  )
}
