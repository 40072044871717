import { combineReducers } from "@reduxjs/toolkit";

import LicenseReducer from "./LicenseReducer";
import TestimonialReducer from "./TestimonialReducer";
import ClientReducer from "./ClientReducers";
import AdminReducer from "./AdminReducers";
import QueryReducer from "./QueryReducers";
import WebQueryReducer from "./WebQueryReducers";

export default combineReducers({
    LicenseStateData:LicenseReducer,
    TestimonialStateData :TestimonialReducer,
    ClientStateData :ClientReducer,
    AdminStateData : AdminReducer,
    QueryStateData :QueryReducer,
    WebQueryStateData:WebQueryReducer


})