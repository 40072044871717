import { put,takeEvery } from "redux-saga/effects";
import { CREATE_CLIENT, CREATE_CLIENT_RED, DELETE_CLIENT, DELETE_CLIENT_RED, GET_CLIENT, GET_CLIENT_RED, UPDATE_CLIENT, UPDATE_CLIENT_RED } from "../../Constant";

import { createRecord, createRecordformData, deleteRecord, getRecord, updateRecord, updateRecordFormData } from './Services'


function* createSaga(action){
    let response= yield createRecordformData("client",action.payload)
    yield put({type:CREATE_CLIENT_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("client")
    yield put({type:GET_CLIENT_RED, payload:response.data})
}

function* updateSaga(action){
   let response = yield updateRecordFormData("client",action.payload)
    yield put({type:UPDATE_CLIENT_RED , payload:response.data})
}

function* deleteSaga(action){
    yield deleteRecord("client",action.payload)
    yield put({type:DELETE_CLIENT_RED, payload:action.payload})
}

export default function* clientSagas(){
    yield takeEvery (CREATE_CLIENT, createSaga)
    yield takeEvery(GET_CLIENT, getSaga)
    yield takeEvery(UPDATE_CLIENT,updateSaga)
    yield takeEvery(DELETE_CLIENT,deleteSaga)
}