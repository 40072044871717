import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {getClient} from "../../Store/ActionCreaters/ClientActionCreaters"

export default function Client() {
  let [data,setData]= useState([])
  let ClientStateData = useSelector((x)=>x.ClientStateData)
  let dispatch =useDispatch()
 
  const goToTop =()=>{
    window.scrollTo({top:0,left:0 ,behavior:"smooth"})
}

  function getAPIData(){
   dispatch(getClient())
   if(ClientStateData.length){
     setData(ClientStateData)
   }
   else setData([])
  }
 
  useEffect(()=>{
    getAPIData()
  },[ClientStateData.length])
 
  return (
    <>
      <div className="container-fluid  ">
        <h1 className="my-4 p-3    text-center">
          Explore Our <span style={{ color: "#F19A17" }}>CLIENTS</span>
        </h1>
        
        <div className="row">
          {ClientStateData?.slice(0,8).map((item, index) => {
            return (
              <div className="col-md-4 col-sm-6 col-lg-3 col " key={index}>
                <div
                  className="card my-2 my-4 mx-2 mx-auto licenseCard"
                  style={{ width: "18rem", height:"16rem" }}
                >
                  <img
                    src={`https://pgc.onrender.com/${item.pic}`}
                    className="card-img-top"
                    alt="..." style={{height:"10rem", objectFit:"fill"}} 
                  />
                  <div className="card-body">
                    <h5>{item.name}</h5>
                    <p className="card-text">
                    <i className="bi bi-geo-alt-fill"></i>  {item.address}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
   <Link to="/allclient-page" className=" text-center fs-5 view-more" style={{ width:"15rem", margin:"auto", }} onClick={goToTop} > View More </Link>

        </div>
    
      </div>
    </>
  );
}
