import React, { useState } from 'react'
import {  Link, useNavigate } from 'react-router-dom'
import FormValidation from './Validation/FormValidation'
import { Helmet } from 'react-helmet'


export default function LoginPage() {
  let [pstatus, setPstatus]=useState(false)
  
  let[data,setData]=useState({
    username:"",
    password:""
  })

  let [errorMessage,setErrorMessage]=useState({
    username:"UserName Is Required",
    password:"Password Field Is Required"
  })
  let [show,setShow]=useState(false)
  let navigate =useNavigate()
 
 function getInputData(e){
     var {name, value}=e.target
     setErrorMessage((old)=>{
      return{
          ...old,
          [name]:FormValidation(e)
      }
  })

     setData((old)=>{
      return{
        ...old,
        [name]:value
      }
     })
 }

 async function postData(e){
  e.preventDefault()
  let error = Object.values(errorMessage).find((x)=>x !== "")
  if(!error){
    let response = await fetch("https://pgc.onrender.com/api/admin/login",{
       method:"POST",
       headers:{
        "content-type":"application/json",
        "authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)
    })
    response = await response.json()
    if(response.result === "Done"){
      localStorage.setItem("login",true)
      localStorage.setItem("name",response.data.name)
      localStorage.setItem("userid",response.data._id)
      localStorage.setItem("role",response.data.role)
      localStorage.setItem("token",response.token)      
      
    if(response.data.role ==="Buyer")
      navigate("/")
    else
     navigate("/admin")
    }
    else{
      setShow(true)
      setErrorMessage((old)=>{
        return{
          ...old,
            username:"Invaild UserName Or Password"
        }
      })
    }
  } 
  else{
    setShow(true)
  }
  
 }
  
 
  return (
    <>
      <Helmet>
      <title>PGC | Login Page </title>
      <meta name="description" content="Perfect global consultancy is a license and web devlopment company . It is Delhi all type license provide company.
      PGC last 15 years  experince of license providing . so pls visit my website. " />
      <meta name="keywords" content="fssai,FSSAI, fssai license, dpcc,Web Development, webdevelopment , delhipolice,
      eatinghouse, guesthouse, mcd, mcdlicense,webdisgn, excide,gst, gstfillig,tradmark,fire/noc, noc,
      delhipolicefire,delhilicense, license, lisense, L-17,P-10,p-10,l-17,gst , MCD delhi,    " />
    </Helmet>
  <div className="container-fluid my-3">
    <div className="row">
        <div className="col-md-6 m-auto">
        <h5 className='bg-success rounded-3 p-2 text-light text-center'>
        <Link to="/admin/create/profile"><i className="bi bi-skip-backward-fill float-end text-light"></i></Link>
          
          Login to Your Account</h5>
        <form onSubmit={postData}>
           <div className="row">
            <div className="mb-3">
                <label className='mx-3'>User Name :</label>
                <input type="text" name="username" onChange={getInputData} placeholder='Enter Your UserName' className='form-control border-2'/>
                {show && errorMessage ? <p className='text-danger text-capitalize'>{errorMessage.username}</p>:""}
            </div>
            </div>
            <div className="row">
            <div className="mb-3">
                <div>
                <label className='mx-3'>Password :</label>
                <input type={pstatus? "text" :"password"} name="password" onChange={getInputData} placeholder='Enter Your Password' className='form-control border-2'/>
                <span className='password-icon'  onClick={()=>setPstatus(!pstatus)}>{pstatus?<i className="bi bi-eye-fill float-end password-icon "></i>:<i className="bi bi-eye-slash-fill  float-end password-icon"></i>}
                {show && errorMessage ? <p className='text-danger text-capitalize fs-6'>{errorMessage.password}</p>:""}

                </span>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3" >
             <button type="submit"  className='btn btn-success login-bg text-light text-center p-2 w-100'>Login</button>
          <Link to={"/forget-password"} className='float-start mx-0' > ForgetPassword?</Link>
          <Link to={"/admin/create/profile"} className='float-end mx-0' > CreateYourAccount?</Link>
            </div>
            </div>

        </form>
        </div>
    </div>
  </div>
    </>
  )
}
