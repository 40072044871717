import { put,takeEvery } from "redux-saga/effects";
import { CREATE_TESTIMONIAL, CREATE_TESTIMONIAL_RED, DELETE_TESTIMONIAL, DELETE_TESTIMONIAL_RED, GET_TESTIMONIAL, GET_TESTIMONIAL_RED } from "../../Constant";

import { createRecord, createRecordformData, deleteRecord, getRecord, } from './Services'


function* createSaga(action){
    let response= yield createRecordformData("testimonial",action.payload)
    yield put({type:CREATE_TESTIMONIAL_RED, payload:response.data})
}

function* getSaga(){
    let response= yield getRecord("testimonial")
    yield put({type:GET_TESTIMONIAL_RED, payload:response.data})
}

// function* updateSaga(action){
//      yield updateRecord("testimonial",action.payload)
//     yield put({type:UPDATE_TESTIMONIAL_RED , payload:action.payload})
// }

function* deleteSaga(action){
    yield deleteRecord("testimonial",action.payload)
    yield put({type:DELETE_TESTIMONIAL_RED, payload:action.payload})
}

export default function* testimonialSagas(){
    yield takeEvery (CREATE_TESTIMONIAL, createSaga)
    yield takeEvery(GET_TESTIMONIAL, getSaga)
    // yield takeEvery(UPDATE_TESTIMONIAL,updateSaga)
    yield takeEvery(DELETE_TESTIMONIAL,deleteSaga)
}