import React from 'react'
import { NavLink} from 'react-router-dom'
export default function SideBar() {
  return (
    <>
   <div className="list-group my-3 side-bar"  id="list-tab" 
    role="tablist">
      <NavLink className="list-group-item list-group-item-action ac my-1" id="list-home-list" data-toggle="list" to="/admin" 
      role="tab" aria-controls="home"> <i className="bi bi-house float-end fs-3"></i>Home</NavLink>
      <NavLink className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" to='/admin/license'role="tab" aria-controls="profile">License
      <i className="bi bi-journal-check float-end fs-3"></i>
      </NavLink>
      <NavLink className="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" to="/admin/testimonial" 
      role="tab" aria-controls="messages">Testimonial<i className="bi bi-chat-right-text float-end fs-3"></i></NavLink>
      <NavLink className="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" to="/admin/client" 
      role="tab" aria-controls="settings">Our Clients<i className="bi bi-people float-end fs-3"></i></NavLink>
      
      <NavLink className="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" to="/admin/allquery" 
      role="tab" aria-controls="settings">All Query<i className="bi bi-question-diamond float-end fs-3"></i></NavLink>
      <NavLink className="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" to="/admin/webquery" 
      role="tab" aria-controls="settings">WebSite Query<i className="bi bi-question-diamond float-end fs-3"></i></NavLink>
    <NavLink className="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" to="/admin/user" 
      role="tab" aria-controls="settings">All Users<i className="bi bi-question-diamond float-end fs-3"></i></NavLink></div>
    
    </>
  )
}
