import { CREATE_CLIENT_RED, DELETE_CLIENT_RED, GET_CLIENT_RED, UPDATE_CLIENT_RED } from "../Constant";

export default function ClientReducer(state=[], action){
let newState, index
    switch(action.type){
        case CREATE_CLIENT_RED:
            newState=[...state]
            newState.push(action.payload)
            return newState

        case GET_CLIENT_RED:
            return action.payload

        case UPDATE_CLIENT_RED:
            index=state.findIndex((x)=>x._id === action.payload._id)
            state[index].name=action.payload.name
            state[index].pic=action.payload.pic
            state[index].address=action.payload.address
            return state
        
        case DELETE_CLIENT_RED:
            return state.filter((x)=> x._id !== action.payload._id)

        
        default :
            return state
    }
}