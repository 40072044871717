const webdata=[
  {name:"E-Commerce",img:"./img/ecom.jpg",id:1,link:"https://themewagon.github.io/kaira/"},
  {name:"Health Care",img:"./img/healtCare.jpg",id:2,link:"https://themewagon.github.io/dentcare/"},
  {name:"Food /Bakery",img:"./img/food.jpg",id:3,link:"https://themewagon.github.io/foody2/"},
  {name:"Travel & Tourism",img:"./img/travell.jpg",id:4,link:"https://themewagon.github.io/wooxtravel/"},
  {name:"Fitness",img:"./img/gym.jpg",id:5,link:"https://themewagon.github.io/zacson/"},
  {name:"Manpower",img:"./img/manpower.jpg",id:6,link:"https://themewagon.github.io/confer/"},
  {name:"Education",img:"./img/education.jpg",id:7,link:"https://themewagon.github.io/elearning/"},
  {name:"Services",img:"./img/services.jpg",id:8,link:"https://themewagon.github.io/Software/"},
  {name:"Transport",img:"./img/transport.jpg",id:9,link:"https://themewagon.github.io/royal2/"},
  {name:"Security Agency",img:"./img/services.jpg",id:10,link:""},
  {name:"Fashion",img:"./img/fashion.png",id:11,link:"https://themewagon.github.io/equipo/"},
  {name:"Construction",img:"./img/civil.jpg",id:12,link:"https://themewagon.github.io/wilcon/"},
]


export default webdata ;